import { AppContext } from "@components/features/appContext/AppContext";
import BreadCumber from "@components/features/breadCumber/BreadCumber";
import InputCheckboxes from "@components/primitives/form/InputCheckboxes";
import InputField from "@components/primitives/form/InputField";
import InputSelect from "@components/primitives/form/InputSelect";
import PreviewImage from "@components/primitives/form/PreviewImage";
import Layout from "@components/primitives/layout/Layout";
import Toast from "@components/primitives/toast/Toast";
import usePostProject from "@hooks/projects/usePostProject";
import useFetchSkills from "@hooks/skills/useFetchSkills";
import { appPath } from "@routes/app";
import { SkillType } from "@utils/SkillType";
import { createTitle } from "@utils/config";
import { translation } from "@utils/translation";
import { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";

const ProjectCreate = () => {
  const { language } = useContext(AppContext);
  const { mutate: postProject, errorMessage, data } = usePostProject(language);
  const { skills } = useFetchSkills(language);
  const navigate = useNavigate();

  const [previewPhoto, setPreviewPhoto] = useState("");

  useEffect(() => {
    if (!errorMessage) return;

    Toast("error", errorMessage);
  }, [errorMessage]);

  useEffect(() => {
    if (!data) return;

    Toast("success", data);
    navigate(appPath.projects.main);
  }, [data, navigate]);

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    const name = e.target.name.value;
    const description = e.target.description.value;
    const url = e.target.url.value;
    const photo = e.target.photo.value;
    const isVisible = e.target.visible.value;

    const projectSkills: string[] = [];

    // eslint-disable-next-line array-callback-return
    skills.map((skill: SkillType) => {
      if (e.target[skill.name].checked) {
        return projectSkills.push(skill.name);
      }
    });

    if (!name || !description || !url || !photo || isVisible === "select") {
      return Toast("error", translation(language).general.not.created);
    }

    const newProject = {
      name,
      description,
      url,
      photo,
      isVisible: isVisible === "true",
      skills: projectSkills,
    };

    await postProject(newProject);
  };

  return (
    <Layout permissionNeeded="projects">
      <Helmet>
        <title>{createTitle(translation(language).sidebar.projects.add)}</title>
      </Helmet>
      <h1 className="text-2xl text-primary">
        {translation(language).sidebar.projects.add}
      </h1>
      <BreadCumber
        items={[
          {
            href: "",
            name: translation(language).sidebar.projects.title,
            isLink: false,
          },
          {
            href: appPath.projects.create,
            name: translation(language).sidebar.projects.add,
            isLink: true,
          },
        ]}
      />

      <form className="relative py-6" onSubmit={(e: any) => handleSubmit(e)}>
        <div className="grid lg:grid-cols-2 gap-9">
          <InputField
            title={translation(language).project.all.table.name}
            name="name"
          />
          <InputField
            title={translation(language).project.all.table.description}
            name="description"
          />
          <InputField
            title={translation(language).project.all.table.url}
            name="url"
          />
          <InputField
            title={translation(language).project.all.table.photo}
            name="photo"
            onBlur={(e: any) => setPreviewPhoto(e.target.value)}
          />
          <InputSelect
            title={translation(language).project.all.table.isVisible.title}
            name="visible"
            options={[
              { label: translation(language).general.text.yes, value: true },
              { label: translation(language).general.text.no, value: false },
            ]}
          />
          <div></div>
          <InputCheckboxes
            title={translation(language).project.all.table.skills}
            name="visible"
            options={
              skills
                ? skills?.map((skill: SkillType) => ({
                    label: skill.name,
                    checked: false,
                  }))
                : []
            }
          />
          <PreviewImage title="Preview" src={previewPhoto} />
        </div>
        <div className="border-t mt-6 pt-3">
          <button className="rounded text-gray-100 p-2 bg-blue-500 hover:shadow-inner hover:bg-blue-700 transition-all duration-300">
            {translation(language).general.actions.add}
          </button>
        </div>
      </form>
    </Layout>
  );
};

export default ProjectCreate;
