import { AppContext } from "@components/features/appContext/AppContext";
import BreadCumber from "@components/features/breadCumber/BreadCumber";
import AlertBox from "@components/primitives/alertBox/AlertBox";
import Layout from "@components/primitives/layout/Layout";
import useFetchCommands from "@hooks/discord/useFetchCommands";
import { appPath } from "@routes/app";
import { CommandType } from "@utils/CommandType";
import { createTitle } from "@utils/config";
import { translation } from "@utils/translation";
import { useContext } from "react";
import { Helmet } from "react-helmet-async";
import Command from "./components/Command";
import LatestCommand from "./components/LatestCommand";

const CommandsPage = () => {
  const { language } = useContext(AppContext);
  const { commands } = useFetchCommands(language);

  return (
    <Layout>
      <Helmet>
        <title>
          {createTitle(translation(language).sidebar.discord.commands)}
        </title>
      </Helmet>
      <h1 className="text-2xl text-primary">
        {translation(language).sidebar.discord.commands}
      </h1>
      <BreadCumber
        items={[
          {
            href: "",
            name: translation(language).sidebar.discord.title,
            isLink: false,
          },
          {
            href: appPath.discord.commands,
            name: translation(language).sidebar.discord.commands,
            isLink: true,
          },
        ]}
      />
      <div className="flex flex-row w-full h-full gap-5 mt-5 ">
        <div className="col-span-12 w-3/4">
          <div className="grid gap-2 grid-cols-1 lg:grid-cols-1">
            <div className="bg-tertiary p-4 shadow-lg rounded-lg">
              <div className="flex flex-row justify-between items-center">
                <div>
                  <h1 className="font-bold text-primary text-base">
                    {translation(language).sidebar.discord.title}
                  </h1>
                  <p className="text-sm text-secondary">
                    {translation(language).discord.commands.description}
                  </p>
                </div>
              </div>
              <div className="mt-4">
                <div className="flex flex-col">
                  <div className="-my-2 overflow-x-auto">
                    <div className="py-2 align-middle inline-block min-w-full">
                      <table className="min-w-full divide-y divide-gray-200">
                        <thead>
                          <tr>
                            <th className="px-6 py-3 bg-secondary text-xs leading-4 font-medium text-primary uppercase tracking-wider">
                              <div className="flex cursor-pointer">
                                <span className="mr-2 uppercase">
                                  {
                                    translation(language).discord.commands.table
                                      .name
                                  }
                                </span>
                              </div>
                            </th>
                            <th className="px-6 py-3 bg-secondary text-xs leading-4 font-medium text-primary uppercase tracking-wider">
                              <div className="flex cursor-pointer">
                                <span className="mr-2 uppercase">
                                  {
                                    translation(language).discord.commands.table
                                      .description
                                  }
                                </span>
                              </div>
                            </th>
                          </tr>
                        </thead>
                        <tbody className="bg-primary text-primary divide-y divide-gray-200">
                          {commands ? (
                            commands?.length > 0 ? (
                              commands?.map((command: CommandType) => (
                                <Command key={command._id} command={command} />
                              ))
                            ) : (
                              <tr>
                                <td colSpan={2} className="pt-5">
                                  <AlertBox
                                    type="info"
                                    message={
                                      translation(language).general.error
                                        .noDataFound
                                    }
                                  />
                                </td>
                              </tr>
                            )
                          ) : (
                            <tr>
                              <td colSpan={2} className="pt-5">
                                <AlertBox
                                  type="danger"
                                  message={
                                    translation(language).general.error
                                      .somethingWentWrong
                                  }
                                />
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <LatestCommand />
      </div>
    </Layout>
  );
};

export default CommandsPage;
