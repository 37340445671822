import { getContainers } from "@repository/portainerRepository";
import { useQuery } from "react-query";

const useFetchContainers = (lang: string) => {
  const { data, isLoading, isError } = useQuery(
    ["containers"],
    () => getContainers(lang),
    {
      refetchInterval: 1000 * 60 * 60,
    }
  );
  return { containers: data, isLoading, isError };
};

export default useFetchContainers;
