type Props = {
  title: string;
  name: string;
  type?: string;
  required?: boolean;
  value?: string;
  onBlur?: any;
  autoComplete?: boolean;
};

const InputField = ({
  title,
  name,
  type = "text",
  required = true,
  value,
  onBlur,
  autoComplete = true,
}: Props) => (
  <div className="border focus-within:border-blue-500 focus-within:text-blue-500 bg-quaternary transition-all duration-500 relative rounded p-1 w-full h-fit">
    <div className="-mt-6 absolute tracking-wider uppercase text-xs">
      <p>
        <label htmlFor={name} className="text-secondary px-1">
          {title}
          {required ? "*" : null}
        </label>
      </p>
    </div>
    <p>
      <input
        id={name}
        name={name}
        defaultValue={value}
        onBlur={onBlur}
        tabIndex={0}
        autoComplete={`${autoComplete}`}
        type={type}
        className="py-1 px-1 text-primary outline-none block h-full w-full bg-transparent"
        {...(!autoComplete && { "data-lpignore": true })}
      />
    </p>
  </div>
);

export default InputField;
