import Arrow from "@components/features/breadCumber/components/Arrow";
import { FileType } from "@utils/FileType";
import React from "react";

type Props = {
  items: FileType[] | [];
  setParents: (e: any) => void;
};

const StorageNavigation = ({ items, setParents }: Props) => {
  const handleClick = (item: FileType) => {
    const indexItem = items.findIndex((i: FileType) => i._id === item._id);
    const splicedItems = items.splice(0, indexItem + 1);
    setParents(splicedItems);
  };

  return (
    <div className="flex items-center py-4 overflow-y-auto whitespace-nowrap">
      <span className="text-primary font-bold">cdn</span>
      {items?.map((item: FileType, index: number) => {
        return (
          <React.Fragment key={index}>
            <Arrow />
            {
              <span
                onClick={() => handleClick(item)}
                className="cursor-pointer text-primary font-thin flex items-center"
              >
                {item.filename}
              </span>
            }
          </React.Fragment>
        );
      })}
    </div>
  );
};

export default StorageNavigation;
