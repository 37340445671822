import {
  executeDeleteRequest,
  executeGetRequest,
  executePostRequest,
  executePutRequest,
} from "@api/client";
import { apiPath, createUserWithIdPath } from "@routes/app";
import { config } from "@utils/config";

export const getUsers = async (lang: string) => {
  const [promise] = executeGetRequest(
    apiPath.users,
    localStorage.getItem(config.localStorage.sessionToken) || "",
    lang
  );
  const result: any = await promise;
  return result.data;
};

export const postUser = async (data: any, lang: string) => {
  const [promise] = executePostRequest(
    apiPath.users,
    data,
    lang,
    localStorage.getItem(config.localStorage.sessionToken) || ""
  );
  const result: any = await promise;
  return result.data;
};

export const putUser = async (id: string, data: any, lang: string) => {
  const [promise] = executePutRequest(
    createUserWithIdPath(id),
    data,
    localStorage.getItem(config.localStorage.sessionToken) || "",
    lang
  );
  const result: any = await promise;
  return result.data;
};

export const deleteUser = async (id: any, lang: string) => {
  const [promise] = executeDeleteRequest(
    createUserWithIdPath(id),
    localStorage.getItem(config.localStorage.sessionToken) || "",
    lang
  );
  const result: any = await promise;
  return result.data;
};
