import { getProjects } from "@repository/projectRepository";
import { useQuery } from "react-query";

const useFetchProjects = (lang: string) => {
  const { data, isLoading, isError } = useQuery(
    ["projects"],
    () => getProjects(lang),
    {
      refetchInterval: 1000 * 60 * 60,
    }
  );
  return { projects: data, isLoading, isError };
};

export default useFetchProjects;
