// Function that formats bytes to human readable format
export const formatBytes = (bytes: any, decimals: number = 2) => {
  if (bytes === 0) return "0 bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  // Return the formatted bytes
  // - 1.2 bytes
  // - 1.2 MB
  // - 1.2 GB
  // - 1.2 TB
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
};
