import { getEvents } from "@repository/eventsRepository";
import { useQuery } from "react-query";

const useFetchEvents = (lang: string) => {
  const { data, isLoading, isError } = useQuery(
    ["events"],
    () => getEvents(lang),
    {
      refetchInterval: 1000 * 60 * 60,
    }
  );
  return { events: data, isLoading, isError };
};

export default useFetchEvents;
