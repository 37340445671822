import { AppContext } from "@components/features/appContext/AppContext";
import BreadCumber from "@components/features/breadCumber/BreadCumber";
import InputSelect from "@components/primitives/form/InputSelect";
import Layout from "@components/primitives/layout/Layout";
import { appPath } from "@routes/app";
import { config, createTitle } from "@utils/config";
import { languageOptions, translation } from "@utils/translation";
import { useContext } from "react";
import { Helmet } from "react-helmet-async";
import DarkLightToggle from "@components/pages/settingsPage/components/DarkLightToggle";

const SettingsPage = () => {
  const { language, setLanguage } = useContext(AppContext);

  const handleLanguageChange = (e: any) => {
    localStorage.setItem(config.localStorage.language, e.value);
    setLanguage(e.value);
  };

  return (
    <Layout>
      <Helmet>
        <title>
          {createTitle(translation(language).sidebar.settings.general)}
        </title>
      </Helmet>
      <h1 className="text-2xl text-primary">
        {translation(language).sidebar.settings.general}
      </h1>
      <BreadCumber
        items={[
          {
            href: "",
            name: translation(language).sidebar.settings.title,
            isLink: false,
          },
          {
            href: appPath.settings,
            name: translation(language).sidebar.settings.general,
            isLink: true,
          },
        ]}
      />

      <div className="w-1/5 mt-5">
        <div className="flex flex-col gap-5">
          <InputSelect
            onChange={(e: any) => handleLanguageChange(e)}
            title={translation(language).settings.language.title}
            required={false}
            name="language"
            options={languageOptions}
            value={
              localStorage.getItem(config.localStorage.language) || "nl_NL"
            }
          />
          <DarkLightToggle />
        </div>
      </div>
    </Layout>
  );
};

export default SettingsPage;
