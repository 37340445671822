import { CommandType } from "@utils/CommandType";

type Props = {
  command: CommandType;
};

const Command = ({ command }: Props) => (
  <tr className="hover:bg-quaternary">
    <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 flex flex-row items-center gap-5">
      {command.name}
    </td>
    <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 ">
      {command.description}
    </td>
  </tr>
);

export default Command;
