import { AppContext } from "@components/features/appContext/AppContext";
import BreadCumber from "@components/features/breadCumber/BreadCumber";
import InputField from "@components/primitives/form/InputField";
import InputCheckboxes from "@components/primitives/form/InputCheckboxes";
import InputSelect from "@components/primitives/form/InputSelect";
import Layout from "@components/primitives/layout/Layout";
import Toast from "@components/primitives/toast/Toast";
import useFetchUsers from "@hooks/users/useFetchUsers";
import usePutUser from "@hooks/users/usePutUser";
import { appPath, createUserPath } from "@routes/app";
import { createTitle } from "@utils/config";
import { translation } from "@utils/translation";
import { UserType } from "@utils/UserType";
import { useContext } from "react";
import { Helmet } from "react-helmet-async";
import { Link, useNavigate, useParams } from "react-router-dom";
import Swal, { SweetAlertResult } from "sweetalert2";

const UserEdit = () => {
  const { id } = useParams();
  const { language } = useContext(AppContext);
  const { users } = useFetchUsers(language);

  const navigate = useNavigate();

  // TODO: Wanneer het niet gevonden is, redirect naar /main
  const user = users?.find((user: UserType) => user.id === id) as UserType;

  const { mutate } = usePutUser(user?.id, language);

  const handleSubmit = (e: any) => {
    e.preventDefault();

    Swal.fire({
      title: translation(language).general.notification.prompt.title,
      text: translation(language).general.notification.prompt.text,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText:
        translation(language).general.notification.prompt.confirm,
      cancelButtonText:
        translation(language).general.notification.prompt.cancel,
    }).then((result: SweetAlertResult<any>) => {
      if (result.value) {
        const formData = new FormData(e.target);

        const password = formData.get("password");
        const confirmPassword = formData.get("confirmPassword");

        if (password !== confirmPassword) {
          return Toast(
            "error",
            translation(language).general.error.passwordMustMatch
          );
        }

        const data = {
          username: formData.get("username"),
          password,
          isBlocked: formData.get("isBlocked") === "true",
          isAdmin: false,
          permissions: {
            projects: formData.get("projects") === "on",
            skills: formData.get("skills") === "on",
            contacts: formData.get("contacts") === "on",
            cdn: formData.get("cdn") === "on",
            users: formData.get("users") === "on",
            discord: formData.get("discord") === "on",
            portainer: formData.get("portainer") === "on",
            events: formData.get("events") === "on",
          },
        };

        mutate(data);
        Toast("success", translation(language).general.success.updated);
        navigate(appPath.users.main);
      } else {
        Toast("error", translation(language).general.not.updated);
      }
    });
  };

  return (
    <Layout permissionNeeded="users">
      <Helmet>
        <title>{createTitle(user?.username)}</title>
      </Helmet>
      <h1 className="text-2xl text-primary">{user?.username}</h1>
      <BreadCumber
        items={[
          {
            href: "",
            name: translation(language).sidebar.users.title,
            isLink: false,
          },
          {
            href: appPath.users.main,
            name: translation(language).sidebar.users.all,
            isLink: true,
          },
          {
            href: createUserPath(user?.id),
            name: user?.id,
            isLink: true,
          },
        ]}
      />

      <form
        className="relative py-6"
        onSubmit={(e: any) => handleSubmit(e)}
        autoComplete="false"
        data-lpignore="true"
      >
        <div className="grid lg:grid-cols-2 gap-9">
          <InputField
            title={translation(language).users.all.table.username}
            name="username"
            value={user?.username}
            autoComplete={false}
          />
          <InputSelect
            title={translation(language).users.all.table.state.title}
            name="isBlocked"
            value={user?.isBlocked}
            options={[
              { label: translation(language).general.text.yes, value: true },
              { label: translation(language).general.text.no, value: false },
            ]}
          />
          <InputField
            title={translation(language).login.password}
            type="password"
            name="password"
            autoComplete={false}
          />
          <InputField
            title={translation(language).login.confirmPassword}
            type="password"
            name="confirmPassword"
            autoComplete={false}
          />
          <InputCheckboxes
            title={translation(language).users.all.table.permissions}
            name="permissions"
            options={[
              { label: "projects", checked: user?.permissions?.projects },
              { label: "skills", checked: user?.permissions?.skills },
              { label: "contacts", checked: user?.permissions?.contacts },
              { label: "cdn", checked: user?.permissions?.cdn },
              { label: "users", checked: user?.permissions?.users },
              { label: "discord", checked: user?.permissions?.discord },
              { label: "portainer", checked: user?.permissions?.portainer },
              { label: "events", checked: user?.permissions?.events },
            ]}
          />
        </div>
        <div className="border-t mt-6 pt-3 flex flex-row gap-5">
          <Link
            to={appPath.users.main}
            className="rounded text-gray-100 p-2 bg-red-500 hover:shadow-inner hover:bg-red-700 transition-all duration-300"
          >
            {translation(language).general.actions.cancel}
          </Link>
          <button className="rounded text-gray-100 p-2 bg-blue-500 hover:shadow-inner hover:bg-blue-700 transition-all duration-300">
            {translation(language).general.actions.save}
          </button>
        </div>
      </form>
    </Layout>
  );
};

export default UserEdit;
