type Props = {
  title: string;
  label: string;
};

const InformationItem = ({ title, label }: Props) => (
  <div className="text-sm font-light text-primary flex flex-col">
    <p className="text-primary text-sm font-bold whitespace-nowrap">{title}</p>
    <p className="text-secondary text-sm font-thin whitespace-nowrap">
      {label}
    </p>
  </div>
);

export default InformationItem;
