import { Link } from "react-router-dom";

type Props = {
  title: string;
  description: string;
  labelNumber?: string;
  number?: number;
  link: string;
};

const BoxItem = ({ title, description, labelNumber, number, link }: Props) => (
  <div className="p-8 space-y-3 border-2 border-blue-400 dark:border-blue-300 rounded-xl">
    <div>
      <h1 className="text-2xl font-semibold text-primary">{title}</h1>
      {number && labelNumber && (
        <p className="text-secondary">
          {labelNumber} {number}
        </p>
      )}
    </div>

    <p className="text-secondary">{description}</p>

    <Link
      to={link}
      className="inline-flex p-2 text-blue-500 capitalize transition-colors duration-200 transform bg-blue-100 rounded-full dark:bg-blue-500 dark:text-white hover:underline hover:text-blue-600 dark:hover:text-blue-500"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="w-6 h-6"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M13 9l3 3m0 0l-3 3m3-3H8m13 0a9 9 0 11-18 0 9 9 0 0118 0z"
        />
      </svg>
    </Link>
  </div>
);

export default BoxItem;
