export const config = {
  // List of prefixes for localStorage
  localStorage: {
    sessionToken: "session",
    language: "language",
    theme: "theme",
  },
};

// Returns the title for HTML
export const createTitle = (title: string) => {
  return `${title} - Mart van Weeghel`;
};
