export const nl_NL = {
  // General
  general: {
    months: [
      "Januari",
      "Februari",
      "Maart",
      "April",
      "Mei",
      "Juni",
      "Juli",
      "Augustus",
      "September",
      "Oktober",
      "November",
      "December",
    ],
    text: {
      or: "of",
      name: "Naam",
      actions: "Acties",
      backToHomePage: "Terug naar de startpagina",
      yes: "Ja",
      no: "Nee",
      select: "Selecteer",
    },
    actions: {
      add: "Toevoegen",
      upload: "Uploaden",
      cancel: "Annuleren",
      save: "Opslaan",
      close: "Sluiten",
      delete: "Verwijderen",
      edit: "Bewerken",
    },
    notification: {
      prompt: {
        title: "Weet je het zeker?",
        text: "Deze actie kan niet ongedaan gemaakt worden.",
        confirm: "Ja, ik weet het zeker!",
        cancel: "Nee, ik neem een andere actie.",
      },
    },
    success: {
      created: "Successvol aangemaakt",
      updated: "Successvol bijgewerkt",
      uploaded: "Successvol geüpload",
      deleted: "Successvol verwijderd",
      copied: "Successvol gekopieerd",
    },
    not: {
      created: "Niet aangemaakt",
      updated: "Niet bijgewerkt",
      uploaded: "Niet geupload",
      deleted: "Niet verwijderd",
    },
    error: {
      pageNotFound: "Pagina niet gevonden",
      somethingWentWrong: "Er is iets fout gegaan. Probeer het opnieuw.",
      starDateLaterThanEndDate: "Startdatum kan niet later zijn dan de einddatum.",
      created: "Er is een fout opgetreden tijdens het aanmaken",
      updated: "Er is een fout opgetreden tijdens het bijwerken",
      deleted: "Er is een fout opgetreden tijdens het verwijderen",
      emptyFields: "Alle velden moeten ingevuld zijn",
      passwordMustMatch: "Wachtwoorden moeten overeen komen",
      noAccess: "Geen toegang",
      noDataFound: "Geen data gevonden",
      blocked: "Account is geblokkeerd",
    },
  },

  // Sidebar
  sidebar: {
    general: {
      title: "Algemeen",
      dashboard: "Dashboard",
    },
    cdn: {
      title: "Content Delivery Network",
      files: "Bestanden",
    },
    portainer: {
      title: "Portainer",
      containers: "Containers",
    },
    projects: {
      title: "Projecten",
      all: "Alle projecten",
      add: "Project toevoegen",
    },
    events: {
      title: "Evenementen",
      all: "Alle evenementen",
      add: "Evenement toevoegen",
    },
    skills: {
      title: "Vaardigheden",
      all: "Alle vaardigheden",
      add: "Vaardigheid toevoegen",
    },
    contacts: {
      title: "Contacten",
      all: "Alle contacten",
      add: "Contact toevoegen",
    },
    users: {
      title: "Gebruikers",
      all: "Alle gebruikers",
      add: "Gebruiker toevoegen",
    },
    discord: {
      title: "Discord",
      main: "Server informatie",
      commands: "Alle commando's",
    },
    settings: {
      title: "Instellingen",
      general: "Algemeen",
      logout: "Uitloggen",
    },
  },

  // Containers

  portainer: {
    containers: {
      title: "Containers",
      description: "Alle containers van Portainer",
      table: {
        name: "Naam",
        image: "Image",
        status: "Status",
        created: "Aangemaakt",
      },
    },
  },

  // Login
  login: {
    title: "Login",
    username: "Gebruikersnaam",
    password: "Wachtwoord",
    confirmPassword: "Herhaal wachtwoord",
    login: "Inloggen",
    copyright: `© ${new Date().getFullYear()} Mart van Weeghel - Alle rechten voorbehouden.`,
    show: "Tonen",
    hide: "Verbergen",
    notification: {
      success: "Succesvol ingelogd!",
      error: "Gebruikersnaam of wachtwoord is onjuist",
    },
  },

  // Dashboard
  dashboard: {
    welcome: "Welkom",
    boxes: {
      cdn: {
        title: "Content Delivery Network",
        description:
          "Hier kan je bestanden uploaden naar de CDN. Deze bestanden zullen direct op de website gebruikt kunnen worden.",
      },
      projects: {
        title: "Projecten",
        description:
          "Hier kan je projecten beheren. Deze projecten zijn getoont op www.martvanweeghel.nl",
        label: "Aantal projecten:",
      },
      skills: {
        title: "Vaardigheden",
        description:
          "Hier kan je vaardigheden beheren. Deze vaardigheden worden gebruikt op www.martvanweeghel.nl",
        label: "Aantal vaardigheden:",
      },
      events: {
        title: "Evenementen",
        description:
          "Hier kan je evenementen beheren. Deze evenementen worden gebruikt op next.martvanweeghel.nl",
        label: "Aantal evenementen:",
      },
      users: {
        title: "Gebruikers",
        description:
          "Hier kan je gebruikers beheren. Deze gebruikers hebben toegang tot deze dashboard.",
      },
      contacts: {
        title: "Contacten",
        description:
          "Hier kan je contacten beheren. Deze contacten worden gebruikt op www.martvanweeghel.nl en op music.martvanweeghel.nl",
        label: "Aantal contacten:",
      },
      discord: {
        title: "Discord",
        description:
          "Hier kan je de discord servers en commando's zien die gebruikt worden door de bot.",
        label: "Aantal servers:",
      },
    },
  },

  // Project
  project: {
    all: {
      description:
        "Hier kan je projecten beheren. Deze projecten zijn getoont op www.martvanweeghel.nl",
      add: "Project toevoegen",
      table: {
        name: "Naam",
        description: "Beschrijving",
        actions: "Acties",
        url: "URL",
        photo: "Foto",
        isVisible: {
          title: "Is zichtbaar?",
          visible: "Zichtbaar",
          hidden: "Verborgen",
        },
        skills: "Project vaardigheden",
      },
    },
  },

  events: {
    all: {
      description:
        "Hier kan je evenementen beheren. Deze evenementen zijn getoont op next.martvanweeghel.nl",
      add: "Evenement toevoegen",
      table: {
        title: "Titel",
        location: "Locatie",
        startDate: "Startdatum",
        endDate: "Einddatum",
        actions: "Acties",
      },
    },
  },

  // Users
  users: {
    all: {
      description:
        "Hier kan je gebruikers toevoegen, verwijderen en aanpassen. Deze gebruikers hebben toegang tot de dashboard.",
      add: "Gebruiker toevoegen",
      table: {
        username: "Gebruikersnaam",
        permissions: "Rechten",
        actions: "Acties",
        state: {
          title: "Is geblokkeerd?",
          blocked: "Geblokkeerd",
          notBlocked: "Niet geblokkeerd",
        },
      },
    },
  },

  //CDN
  cdn: {
    table: {
      file: "Bestand",
      type: "Type",
      size: "Grootte",
      createdAt: "Aangemaakt op",
      changedAt: "Gewijzigd op",
    },
    noFile: "Geen bestanden gevonden",
    addFileOrFolder: "Bestand of map toevoegen",
    file: "Bestand",
    folder: "Map",
    fileUpload: "Bestand uploaden",
    folderCreate: "Map aanmaken",
    dragFile: "Sleep bestanden hierheen",
    searchFile: "Zoek de bestand op je computer",
    linkToCDN: "Link naar CDN",
    openLink: "Open link",
    copyLink: "Kopieer link",
  },

  // Skills
  skills: {
    all: {
      title: "Vaardigheden",
      description:
        "Hier kan je vaardigheden beheren. Deze vaardigheden worden gebruikt op www.martvanweeghel.nl",
      addSkill: "Vaardigheid toevoegen",
      table: {
        skill: "Vaardigheid",
        name: "Naam",
        icon: "Icoon",
        percentage: "Percentage",
        isVisible: {
          title: "Is zichtbaar?",
          visible: "Zichtbaar",
          hidden: "Verborgen",
        },
      },
    },
  },

  // Contacts
  contacts: {
    all: {
      title: "Contacten",
      description: {
        main: "Hier kan je contacten beheren. Deze contacten worden gebruikt op www.martvanweeghel.nl",
        music:
          "Hier kan je contacten beheren. Deze contacten worden gebruikt op music.martvanweeghel.nl",
      },
      addContact: "Contact toevoegen",
      table: {
        contact: "Contact",
        name: "Naam",
        icon: "Icoon",
        type: "Type",
        url: "URL",
        isVisible: {
          title: "Is zichtbaar?",
          visible: "Zichtbaar",
          hidden: "Verborgen",
        },
      },
    },
  },

  // Discord
  discord: {
    main: {
      description: "Hier kan je de informatie van de server bekijken.",
      table: {
        guildId: "Guild ID",
        name: "Naam",
        icon: "Icoon",
        ownerId: "Eigenaar ID",
        nameAcronym: "Naam acroniem",
        prefferedLocale: "Voorkeurstaal",
      },
    },
    commands: {
      description: "Hier kan je alle commando's bekijken.",
      latestCommand: "Laatste commando",
      latestCommandDescription: "Laatste commando uitgevoerd door de bot.",
      table: {
        name: "Naam",
        description: "Beschrijving",
      },
    },
  },

  // Settings
  settings: {
    language: {
      title: "Taal",
    },
  },
};
