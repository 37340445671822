import classNames from "classnames";
import { Link, useMatch, useResolvedPath } from "react-router-dom";

type Props = {
  Icon: any;
  href: string;
  name: string;
  onClick?: () => void;
};

const MenuItem = ({ Icon, href, name, onClick }: Props) => {
  let resolvedTo = useResolvedPath(href);
  let matchTo = useMatch({ path: resolvedTo.pathname });

  const className = classNames(
    "relative flex flex-row items-center h-11 focus:outline-none hover:bg-quaternary text-primary border-l-4 border-transparent hover:border-blue-500 pr-6 ease-in-out duration-300",
    {
      "border-blue-500 bg-secondary": matchTo,
    }
  );

  return (
    <li onClick={() => onClick}>
      <Link to={href} className={className}>
        <span className="inline-flex justify-center items-center ml-4">
          <Icon />
        </span>
        <span className="ml-2 text-sm tracking-wide truncate">{name}</span>
      </Link>
    </li>
  );
};

export default MenuItem;
