import { AppContext } from "@components/features/appContext/AppContext";
import { config } from "@utils/config";
import classNames from "classnames";
import { useContext } from "react";

const DarkLightToggle = () => {
  const { theme, setTheme } = useContext(AppContext);

  const toggleTheme = () => {
    const newTheme = theme === "dark" ? "light" : "dark";
    localStorage.setItem(config.localStorage.theme, newTheme);
    setTheme(newTheme);
  };

  const outerDivClassName = classNames(
    "w-14 h-7 flex items-center rounded-full mx-3 px-1",
    {
      "bg-gray-300": theme === "light",
      "bg-blue-500": theme === "dark",
    }
  );

  const innerDivClassName = classNames(
    "bg-white w-5 h-5 rounded-full shadow-md transform",
    {
      "translate-x-7": theme === "dark",
    }
  );

  return (
    <div
      className="flex items-center cursor-pointer"
      onClick={() => toggleTheme()}
    >
      <svg
        className="h-6 w-6 text-primary"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M12 3v1m0 16v1m9-9h-1M4 12H3m15.364 6.364l-.707-.707M6.343 6.343l-.707-.707m12.728 0l-.707.707M6.343 17.657l-.707.707M16 12a4 4 0 11-8 0 4 4 0 018 0z"
        />
      </svg>
      <div className={outerDivClassName}>
        <div className={innerDivClassName}></div>
      </div>
      <svg
        className="h-6 w-6 text-primary"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M20.354 15.354A9 9 0 018.646 3.646 9.003 9.003 0 0012 21a9.003 9.003 0 008.354-5.646z"
        />
      </svg>
    </div>
  );
};

export default DarkLightToggle;
