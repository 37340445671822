import InputField from "@components/primitives/form/InputField";
import Toast from "@components/primitives/toast/Toast";
import usePostFile from "@hooks/files/usePostFile";
import { ReactComponent as UploadSVG } from "@assets/images/upload.svg";
import { useContext, useState } from "react";
import Dropzone, { DropzoneState } from "react-dropzone";
import { translation } from "@utils/translation";
import { AppContext } from "@components/features/appContext/AppContext";
import Thumbs from "./Thumbs";

type Props = {
  setIsUploadFilePopupOpen: (isOpen: boolean) => void;
  parentId: string;
};

const UploadFilePopup = ({ setIsUploadFilePopupOpen, parentId }: Props) => {
  const { language } = useContext(AppContext);
  const [file, setFile] = useState(null) as any;
  const [uploadedFile, setUploadedFile] = useState(null) as any;
  const [fileName, setFileName] = useState("");

  const { mutate } = usePostFile(parentId, language);

  const onDrop = (acceptedFiles: any) => {
    if (acceptedFiles.length > 0) {
      const selectedFile = acceptedFiles[0];

      // Set uploadedFile for uploading
      setUploadedFile(selectedFile);

      // Set file for preview
      setFile({ ...selectedFile, preview: URL.createObjectURL(selectedFile) });
      setFileName(selectedFile.name);
    }
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    if (e.target.name.value === "" || uploadedFile === null) {
      return Toast("error", translation(language).general.error.emptyFields);
    }

    await mutate({
      file: uploadedFile,
      parent: parentId,
      fileName: e.target.name.value,
      size: uploadedFile.size,
      extension: uploadedFile.type.split("/")[1],
    });

    setIsUploadFilePopupOpen(false);
    Toast("success", translation(language).general.success.uploaded);
  };

  return (
    <div
      className="relative z-10"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div className="fixed inset-0 bg-gray-500 dark:bg-gray-900 bg-opacity-75 dark:bg-opacity-75 transition-opacity"></div>
      <div className="fixed z-10 inset-0 overflow-y-auto">
        <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
          <form
            className="relative bg-primary rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-lg sm:w-full"
            onSubmit={(e: any) => handleSubmit(e)}
          >
            <div className="bg-primary px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
              <div className="sm:flex sm:items-start">
                <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left w-full">
                  <h3
                    className="text-lg leading-6 font-medium text-primary"
                    id="modal-title"
                  >
                    {translation(language).cdn.fileUpload}
                  </h3>
                  <div className="my-8 space-y-8">
                    <Dropzone
                      onDrop={(acceptedVideoTypes: any) =>
                        onDrop(acceptedVideoTypes)
                      }
                      multiple={false}
                    >
                      {({ getRootProps, getInputProps }: DropzoneState) => (
                        <section>
                          <div
                            className="border-2 border-gray-400 text-primary border-dashed rounded-md w-full flex items-center flex-col py-5"
                            {...getRootProps()}
                          >
                            <input {...getInputProps()} />
                            <UploadSVG className="w-20 h-20" />
                            <p>{translation(language).cdn.dragFile}</p>
                            <p>{translation(language).general.text.or}</p>
                            <p className="text-secondary underline cursor-pointer">
                              {translation(language).cdn.searchFile}
                            </p>
                            <div className="mt-5">
                              <Thumbs file={file} />
                            </div>
                          </div>
                        </section>
                      )}
                    </Dropzone>
                    {fileName && (
                      <InputField
                        title={translation(language).general.text.name}
                        required={false}
                        name="name"
                        value={fileName}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="bg-primary px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
              <button
                type="submit"
                className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:ml-3 sm:w-auto sm:text-sm"
              >
                {translation(language).general.actions.upload}
              </button>
              <button
                onClick={() => setIsUploadFilePopupOpen(false)}
                type="button"
                className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
              >
                {translation(language).general.actions.cancel}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default UploadFilePopup;
