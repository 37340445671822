import { AppContext } from "@components/features/appContext/AppContext";
import Toast from "@components/primitives/toast/Toast";
import useDeleteSkill from "@hooks/skills/useDeleteSkill";
import { createSkillPath } from "@routes/app";
import { SkillType } from "@utils/SkillType";
import { translation } from "@utils/translation";
import classNames from "classnames";
import { useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import Swal, { SweetAlertResult } from "sweetalert2";

type Props = {
  skill: SkillType;
};

const Skill = ({ skill }: Props) => {
  const { _id, name, percentage, isVisible, icon } = skill;
  const { language } = useContext(AppContext);
  const { mutate: deleteSkill, errorMessage, data } = useDeleteSkill(language);

  useEffect(() => {
    if (!errorMessage) return;

    Toast("error", errorMessage);
  }, [errorMessage]);

  useEffect(() => {
    if (!data) return;

    Toast("success", data);
  }, [data]);

  const visibleClassName = classNames(
    "px-2 py-1 font-semibold leading-tight rounded-sm",
    {
      "text-green-700 bg-green-100": isVisible,
      "text-red-700 bg-red-100": !isVisible,
    }
  );

  const handleDelete = async () => {
    Swal.fire({
      title: translation(language).general.notification.prompt.title,
      text: translation(language).general.notification.prompt.text,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText:
        translation(language).general.notification.prompt.confirm,
      cancelButtonText:
        translation(language).general.notification.prompt.cancel,
    }).then(async (result: SweetAlertResult<any>) => {
      if (result.value) {
        await deleteSkill({ id: _id });
        Toast("success", translation(language).general.success.deleted);
      } else {
        Toast("error", translation(language).general.not.deleted);
      }
    });
  };

  return (
    <tr className="hover:bg-quaternary">
      <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 flex flex-row items-center gap-5">
        <img src={icon} alt={name} className="w-auto h-8" />
        <p>{name}</p>
      </td>
      <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 ">
        {percentage}%
      </td>
      <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5">
        <span className={visibleClassName}>
          {isVisible
            ? translation(language).skills.all.table.isVisible.visible
            : translation(language).skills.all.table.isVisible.hidden}
        </span>
      </td>
      <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5">
        <div className="flex space-x-4">
          <Link
            to={createSkillPath(_id)}
            className="text-blue-500 hover:text-blue-600 flex flex-row"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="w-5 h-5 mr-1"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
              />
            </svg>
            <p>{translation(language).general.actions.edit}</p>
          </Link>
          <button
            onClick={() => handleDelete()}
            className="text-red-500 hover:text-red-600 flex flex-row"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="w-5 h-5 mr-1 ml-3"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
              />
            </svg>
            <p>{translation(language).general.actions.delete}</p>
          </button>
        </div>
      </td>
    </tr>
  );
};

export default Skill;
