import { ContainerType } from "@utils/ContainerType";
import classNames from "classnames";

type Props = {
  container: ContainerType;
};

const Container = ({ container }: Props) => {
  const { name, image, status, created } = container;

  const visibleClassName = classNames(
    "px-2 py-1 font-semibold leading-tight rounded-sm",
    {
      "text-green-700 bg-green-100": status === "running",
      "text-red-700 bg-red-100": status === "exited" || status === "stopped",
      "text-blue-700 bg-blue-100": status === "created",
      "text-orange-700 bg-orange-100": status === "restarting",
      "text-gray-700 bg-gray-100": status === "paused",
    }
  );

  return (
    <tr className="hover:bg-quaternary">
      <td
        className="px-6 py-4 whitespace-no-wrap text-sm leading-5"
        title={name}
      >
        <p>{name}</p>
      </td>
      <td
        className="px-6 py-4 text-sm leading-5 truncate max-w-xs"
        title={image}
      >
        <p>{image}</p>
      </td>
      <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5">
        <span className={visibleClassName}>{status}</span>
      </td>
      <td
        className="px-6 py-4 text-sm leading-5 truncate max-w-xs"
        title={created}
      >
        <p>{created}</p>
      </td>
    </tr>
  );
};

export default Container;
