import { ReactComponent as FolderSVG } from "@assets/images/folder.svg";
import { ReactComponent as HomeSVG } from "@assets/images/home.svg";
import { ReactComponent as LogoutSVG } from "@assets/images/logout.svg";
import { ReactComponent as ListSVG } from "@assets/images/list.svg";
import { ReactComponent as CreateSVG } from "@assets/images/create.svg";
import { ReactComponent as SettingsSVG } from "@assets/images/settings.svg";
import { ReactComponent as DiscordSVG } from "@assets/images/discord.svg";
import LogoPNG from "@assets/images/logo.png";
import { useNavigate } from "react-router-dom";
import { useContext, useEffect } from "react";
import { appPath } from "@routes/app";
import usePostLogout from "@hooks/entries/usePostLogout";
import { UserType } from "@utils/UserType";
import Toast from "@components/primitives/toast/Toast";
import MenuItem from "./components/MenuItem";
import MenuButton from "./components/MenuButton";
import { AppContext } from "../appContext/AppContext";
import { translation } from "@utils/translation";

const SideMenu = () => {
  const { sessionToken, setSessionToken, setUser, user, language } =
    useContext(AppContext);
  const { mutate: logoutMutation, data: logoutData } = usePostLogout(language);

  const navigate = useNavigate();

  const handleLogout = () => {
    logoutMutation({ token: sessionToken });
  };

  useEffect(() => {
    if (!logoutData) return;

    Toast("success", logoutData);
    setUser({} as UserType);
    setSessionToken("");
    navigate(appPath.login);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [logoutData]);

  return (
    <div className="fixed min-h-screen flex flex-col flex-auto flex-shrink-0 antialiased text-primary ">
      <div className="fixed flex flex-col top-0 left-0 w-44 whitespace-nowrap md:w-64 bg-tertiary h-full shadow-lg ">
        <div className="flex flex-col gap-5 items-center justify-center h-fit border-b">
          <img src={LogoPNG} alt="Dashboard" className="w-24" />
        </div>
        <div className="overflow-y-auto overflow-x-hidden flex-grow">
          <ul className="flex flex-col py-4 space-y-5">
            <div>
              <MenuItem
                Icon={HomeSVG}
                href={appPath.dashboard}
                name={translation(language).sidebar.general.dashboard}
              />
            </div>
            {user?.permissions?.cdn && (
              <div>
                <li className="px-5">
                  <div className="flex flex-row items-center h-8">
                    <div className="text-sm font-light truncate tracking-wide text-primary uppercase">
                      {translation(language).sidebar.cdn.title}
                    </div>
                  </div>
                </li>
                <MenuItem
                  Icon={FolderSVG}
                  href={appPath.cdn}
                  name={translation(language).sidebar.cdn.files}
                />
              </div>
            )}
            {user?.permissions?.portainer && (
              <div>
                <li className="px-5">
                  <div className="flex flex-row items-center h-8">
                    <div className="text-sm font-light truncate tracking-wide text-primary uppercase">
                      {translation(language).sidebar.portainer.title}
                    </div>
                  </div>
                </li>
                <MenuItem
                  Icon={ListSVG}
                  href={appPath.portainer.containers}
                  name={translation(language).sidebar.portainer.containers}
                />
              </div>
            )}
            {user?.permissions?.projects && (
              <div>
                <li className="px-5">
                  <div className="flex flex-row items-center h-8">
                    <div className="text-sm font-light truncate tracking-wide text-primary uppercase">
                      {translation(language).sidebar.projects.title}
                    </div>
                  </div>
                </li>
                <MenuItem
                  Icon={ListSVG}
                  href={appPath.projects.main}
                  name={translation(language).sidebar.projects.all}
                />
                <MenuItem
                  Icon={CreateSVG}
                  href={appPath.projects.create}
                  name={translation(language).sidebar.projects.add}
                />
              </div>
            )}
            {user?.permissions?.skills && (
              <div>
                <li className="px-5">
                  <div className="flex flex-row items-center h-8">
                    <div className="text-sm font-light truncate tracking-wide text-primary uppercase">
                      {translation(language).sidebar.skills.title}
                    </div>
                  </div>
                </li>
                <MenuItem
                  Icon={ListSVG}
                  href={appPath.skills.main}
                  name={translation(language).sidebar.skills.all}
                />
                <MenuItem
                  Icon={CreateSVG}
                  href={appPath.skills.create}
                  name={translation(language).sidebar.skills.add}
                />
              </div>
            )}
            {user?.permissions?.contacts && (
              <div>
                <li className="px-5">
                  <div className="flex flex-row items-center h-8">
                    <div className="text-sm font-light truncate tracking-wide text-primary uppercase">
                      {translation(language).sidebar.contacts.title}
                    </div>
                  </div>
                </li>
                <MenuItem
                  Icon={ListSVG}
                  href={appPath.contacts.main}
                  name={translation(language).sidebar.contacts.all}
                />
                <MenuItem
                  Icon={CreateSVG}
                  href={appPath.contacts.create}
                  name={translation(language).sidebar.contacts.add}
                />
              </div>
            )}
            {user?.permissions?.users && (
              <div>
                <li className="px-5">
                  <div className="flex flex-row items-center h-8">
                    <div className="text-sm font-light truncate tracking-wide text-primary uppercase">
                      {translation(language).sidebar.users.title}
                    </div>
                  </div>
                </li>
                <MenuItem
                  Icon={ListSVG}
                  href={appPath.users.main}
                  name={translation(language).sidebar.users.all}
                />
                <MenuItem
                  Icon={CreateSVG}
                  href={appPath.users.create}
                  name={translation(language).sidebar.users.add}
                />
              </div>
            )}
            {user?.permissions?.events && (
              <div>
                <li className="px-5">
                  <div className="flex flex-row items-center h-8">
                    <div className="text-sm font-light truncate tracking-wide text-primary uppercase">
                      {translation(language).sidebar.events.title}
                    </div>
                  </div>
                </li>
                <MenuItem
                  Icon={ListSVG}
                  href={appPath.events.main}
                  name={translation(language).sidebar.events.all}
                />
                <MenuItem
                  Icon={CreateSVG}
                  href={appPath.events.create}
                  name={translation(language).sidebar.events.add}
                />
              </div>
            )}
            {user?.permissions?.discord && (
              <div>
                <li className="px-5">
                  <div className="flex flex-row items-center h-8">
                    <div className="text-sm font-light truncate tracking-wide text-primary uppercase">
                      {translation(language).sidebar.discord.title}
                    </div>
                  </div>
                </li>
                <MenuItem
                  Icon={DiscordSVG}
                  href={appPath.discord.main}
                  name={translation(language).sidebar.discord.main}
                />
                <MenuItem
                  Icon={DiscordSVG}
                  href={appPath.discord.commands}
                  name={translation(language).sidebar.discord.commands}
                />
              </div>
            )}
            <div>
              <li className="px-5">
                <div className="flex flex-row items-center h-8">
                  <div className="text-sm font-light truncate tracking-wide text-primary uppercase">
                    {translation(language).sidebar.settings.title}
                  </div>
                </div>
              </li>
              <MenuItem
                Icon={SettingsSVG}
                href={appPath.settings}
                name={translation(language).sidebar.settings.general}
              />
              <MenuButton
                Icon={LogoutSVG}
                onClick={handleLogout}
                name={translation(language).sidebar.settings.logout}
              />
            </div>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default SideMenu;
