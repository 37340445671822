import { AppContext } from "@components/features/appContext/AppContext";
import Toast from "@components/primitives/toast/Toast";
import useDeleteFolder from "@hooks/files/useDeleteFolder";
import { dateToReadDate } from "@utils/dateToReadDate";
import { FileType } from "@utils/FileType";
import { formatBytes } from "@utils/formatBytes";
import { getIcon } from "@utils/getIcon";
import { translation } from "@utils/translation";
import { useOutsideAlerter } from "@utils/useOutsideAlerter";
import { useContext, useRef, useState } from "react";
import Swal, { SweetAlertResult } from "sweetalert2";
import ContextMenu from "./ContextMenu";

type Props = {
  file: FileType;
  parents: any;
  setParents: (parent: any) => void;
  setFileInfo: (file: any) => void;
  isContextMenu: string;
  setIsContextMenu: (isContextMenu: string) => void;
};

const FileItem = ({
  file,
  parents,
  setParents,
  setFileInfo,
  isContextMenu,
  setIsContextMenu,
}: Props) => {
  const { language } = useContext(AppContext);
  const [contextMenu, setContextMenu] = useState({ x: 0, y: 0 });
  const contextMenuRef = useRef(null);
  const iconType = getIcon(file.extension);
  const { mutate: deleteFolder } = useDeleteFolder(file.parent, language);

  const handleClick = () => {
    if (file.extension === "folder") {
      setParents([...parents, file]);
    } else {
      setFileInfo(file);
    }
  };

  const handleDelete = () => {
    Swal.fire({
      title: translation(language).general.notification.prompt.title,
      text: translation(language).general.notification.prompt.text,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText:
        translation(language).general.notification.prompt.confirm,
      cancelButtonText:
        translation(language).general.notification.prompt.cancel,
    }).then((result: SweetAlertResult<any>) => {
      if (result.value) {
        Toast("success", translation(language).general.success.deleted);
        setIsContextMenu("");
        deleteFolder(file._id);
      } else {
        Toast("error", translation(language).general.not.deleted);
      }
    });
  };

  const handleContextMenu = (e: any) => {
    e.preventDefault();

    setContextMenu({
      x: e.clientX,
      y: e.clientY,
    });

    setIsContextMenu(file._id);
  };

  useOutsideAlerter(contextMenuRef, () => {
    setIsContextMenu("");
  });

  return (
    <>
      {isContextMenu === file._id && file.extension === "folder" && (
        <ContextMenu
          language={language}
          contextMenuRef={contextMenuRef}
          contextMenu={contextMenu}
          handleDelete={handleDelete}
        />
      )}
      <tr
        onClick={() => handleClick()}
        className="text-primary hover:bg-quaternary cursor-pointer"
        onContextMenu={(e: any) => handleContextMenu(e)}
        aria-expanded="true"
        aria-haspopup="true"
      >
        <td className="text-left">
          <div className="flex flex-row items-center gap-5">
            <div className="flex-shrink-0">
              <img src={iconType} alt="placeholder" className="w-8" />
            </div>
            <p>
              {file.filename}
              {file.extension !== "folder" ? `.${file.extension}` : null}
            </p>
          </div>
        </td>
        <td className="text-left">
          <div className="flex flex-row items-center gap-1">
            <p>{formatBytes(file.size)}</p>
          </div>
        </td>
        <td className="text-left">
          <div className="flex flex-row items-center gap-1">
            <p>{dateToReadDate(file.createdAt)}</p>
          </div>
        </td>
        <td className="text-left">
          <div className="flex flex-row items-center gap-1">
            <p>{dateToReadDate(file.updatedAt)}</p>
          </div>
        </td>
      </tr>
    </>
  );
};

export default FileItem;
