import { AppContext } from "@components/features/appContext/AppContext";
import SideMenu from "@components/features/sideMenu/SideMenu";
import useCheckToken from "@hooks/token/useCheckToken";
import { appPath } from "@routes/app";
import { config } from "@utils/config";
import { UserType } from "@utils/UserType";
import { useContext, useEffect } from "react";
import Loading from "react-loading";
import { useNavigate } from "react-router-dom";
import Toast from "../toast/Toast";
import { isEmpty } from "@utils/isEmpty";
import { translation } from "@utils/translation";

type Props = {
  permissionNeeded?:
    | "projects"
    | "skills"
    | "contacts"
    | "cdn"
    | "users"
    | "discord"
    | "events"
    | "portainer";
  children: React.ReactNode | React.ReactNode[];
};

const Layout = ({ permissionNeeded, children }: Props) => {
  const {
    sessionToken,
    setSessionToken,
    setUser,
    user,
    setIsLoading,
    isLoading,
    language,
    setLanguage,
    setTheme,
  } = useContext(AppContext);
  const {
    mutate: checkToken,
    data: checkTokenData,
    errorMessage: checkTokenErrorMessage,
  } = useCheckToken(language);
  const navigate = useNavigate();

  useEffect(() => {
    if (!sessionToken) {
      setSessionToken(localStorage.getItem(config.localStorage.sessionToken));
      checkToken({
        token:
          localStorage.getItem(config.localStorage.sessionToken) ||
          ("" as string),
      });
      return;
    }

    setLanguage(
      localStorage.getItem(config.localStorage.language) || ("" as string)
    );
    setTheme(localStorage.getItem(config.localStorage.theme) || ("" as string));
    setIsLoading(false);

    if (!isEmpty(user)) {
      if (permissionNeeded) {
        if (!user.permissions[permissionNeeded]) {
          navigate(appPath.dashboard);
          Toast("error", translation(language).general.error.noAccess);
        }
      }

      if (user.isBlocked) {
        setSessionToken("");
        setUser({} as UserType);
        localStorage.removeItem(config.localStorage.sessionToken);
        Toast("error", translation(language).general.error.blocked);
        navigate(appPath.login);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  });

  useEffect(() => {
    if (!checkTokenErrorMessage) return;

    setSessionToken("");
    setUser({} as UserType);
    localStorage.removeItem(config.localStorage.sessionToken);
    Toast("error", checkTokenErrorMessage);
    navigate(appPath.login);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkTokenErrorMessage]);

  useEffect(() => {
    if (!checkTokenData) return;
    setIsLoading(false);
    setUser(checkTokenData.user);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkTokenData]);

  return (
    <>
      {!isLoading ? (
        <div className="bg-primary h-screen">
          <SideMenu />
          <main className="ml-44 md:ml-64 py-5 px-5 h-max bg-primary">
            {children}
          </main>
        </div>
      ) : (
        <div className="flex items-center justify-center w-screen h-screen bg-primary">
          <Loading color="lightblue" type="spin" />
        </div>
      )}
    </>
  );
};

export default Layout;
