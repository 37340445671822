import { AppContext } from "@components/features/appContext/AppContext";
import InputField from "@components/primitives/form/InputField";
import Toast from "@components/primitives/toast/Toast";
import usePostFolder from "@hooks/files/usePostFolder";
import { translation } from "@utils/translation";
import { useContext } from "react";

type Props = {
  setIsFolderPopupOpen: (isOpen: boolean) => void;
  parentId: string;
};

const AddFolderPopup = ({ setIsFolderPopupOpen, parentId }: Props) => {
  const { language } = useContext(AppContext);
  const { mutate } = usePostFolder(parentId, language);

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    if (e.target.name.value === "") {
      return Toast("error", translation(language).general.error.emptyFields);
    }

    await mutate({
      fileName: e.target.name.value,
    });

    setIsFolderPopupOpen(false);
    Toast("success", translation(language).general.success.created);
  };

  return (
    <div
      className="relative z-10"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div className="fixed inset-0 bg-gray-500 dark:bg-gray-900 bg-opacity-75 dark:bg-opacity-75 transition-opacity"></div>
      <div className="fixed z-10 inset-0 overflow-y-auto">
        <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
          <form
            className="relative bg-primary rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-lg sm:w-full"
            onSubmit={(e: any) => handleSubmit(e)}
          >
            <div className="bg-primary px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
              <div className="sm:flex sm:items-start">
                <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left w-full">
                  <h3
                    className="text-lg leading-6 font-medium text-primary"
                    id="modal-title"
                  >
                    {translation(language).cdn.folderCreate}
                  </h3>
                  <div className="my-8 space-y-8">
                    <InputField
                      title={translation(language).general.text.name}
                      required={false}
                      name="name"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="bg-primary px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
              <button
                type="submit"
                className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:ml-3 sm:w-auto sm:text-sm"
              >
                {translation(language).general.actions.add}
              </button>
              <button
                onClick={() => setIsFolderPopupOpen(false)}
                type="button"
                className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
              >
                {translation(language).general.actions.cancel}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddFolderPopup;
