import { AppContext } from "@components/features/appContext/AppContext";
import BreadCumber from "@components/features/breadCumber/BreadCumber";
import InputField from "@components/primitives/form/InputField";
import InputCheckboxes from "@components/primitives/form/InputCheckboxes";
import InputSelect from "@components/primitives/form/InputSelect";
import Layout from "@components/primitives/layout/Layout";
import Toast from "@components/primitives/toast/Toast";
import usePostUser from "@hooks/users/usePostUser";
import { appPath } from "@routes/app";
import { createTitle } from "@utils/config";
import { translation } from "@utils/translation";
import { useContext, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";

const UserCreate = () => {
  const { language } = useContext(AppContext);
  const { mutate: postUser, errorMessage, data } = usePostUser(language);
  const navigate = useNavigate();

  useEffect(() => {
    if (!errorMessage) return;

    Toast("error", errorMessage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorMessage]);

  useEffect(() => {
    if (!data) return;

    Toast("success", data);
    navigate(appPath.users.main);
  }, [data, navigate]);

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    const username = e.target.username.value;
    const password = e.target.password.value;
    const confirmPassword = e.target.confirmPassword.value;
    const isBlocked = e.target.isBlocked.value;

    const projectsPermission = e.target.projects.checked;
    const skillsPermission = e.target.skills.checked;
    const contactsPermission = e.target.contacts.checked;
    const cdnPermission = e.target.cdn.checked;
    const usersPermission = e.target.users.checked;
    const discordPermission = e.target.discord.checked;
    const portainerPermission = e.target.portainer.checked;
    const eventsPermission = e.target.events.checked;

    if (password !== confirmPassword) {
      return Toast(
        "error",
        translation(language).general.error.passwordMustMatch
      );
    }

    if (!username || !password || !confirmPassword || isBlocked === "select") {
      return Toast("error", translation(language).general.error.emptyFields);
    }

    const newUser = {
      username,
      password,
      isBlocked: isBlocked === "true",
      isAdmin: false,
      permissions: {
        projects: projectsPermission,
        skills: skillsPermission,
        contacts: contactsPermission,
        cdn: cdnPermission,
        users: usersPermission,
        discord: discordPermission,
        portainer: portainerPermission,
        events: eventsPermission,
      },
    };

    await postUser(newUser);
  };

  return (
    <Layout permissionNeeded="users">
      <Helmet>
        <title>{createTitle(translation(language).sidebar.users.add)}</title>
      </Helmet>
      <h1 className="text-2xl text-primary">
        {translation(language).sidebar.users.add}
      </h1>
      <BreadCumber
        items={[
          {
            href: "",
            name: translation(language).sidebar.users.title,
            isLink: false,
          },
          {
            href: appPath.users.create,
            name: translation(language).sidebar.users.add,
            isLink: true,
          },
        ]}
      />

      <form className="relative py-6" onSubmit={(e: any) => handleSubmit(e)}>
        <div className="grid lg:grid-cols-2 gap-9">
          <InputField
            title={translation(language).users.all.table.username}
            name="username"
            autoComplete={false}
          />
          <InputSelect
            title={translation(language).users.all.table.state.title}
            name="isBlocked"
            options={[
              { label: translation(language).general.text.yes, value: true },
              { label: translation(language).general.text.no, value: false },
            ]}
          />
          <InputField
            title={translation(language).login.password}
            type="password"
            name="password"
            autoComplete={false}
          />
          <InputField
            title={translation(language).login.confirmPassword}
            type="password"
            name="confirmPassword"
            autoComplete={false}
          />
          <InputCheckboxes
            title={translation(language).users.all.table.permissions}
            name="permissions"
            options={[
              { label: "projects", checked: false },
              { label: "skills", checked: false },
              { label: "contacts", checked: false },
              { label: "cdn", checked: false },
              { label: "users", checked: false },
              { label: "discord", checked: false },
              { label: "portainer", checked: false },
              { label: "events", checked: false },
            ]}
          />
        </div>
        <div className="border-t mt-6 pt-3">
          <button className="rounded text-gray-100 p-2 bg-blue-500 hover:shadow-inner hover:bg-blue-700 transition-all duration-300">
            {translation(language).general.actions.add}
          </button>
        </div>
      </form>
    </Layout>
  );
};

export default UserCreate;
