import { AppContext } from "@components/features/appContext/AppContext";
import BreadCumber from "@components/features/breadCumber/BreadCumber";
import AlertBox from "@components/primitives/alertBox/AlertBox";
import Layout from "@components/primitives/layout/Layout";
import useFetchProjects from "@hooks/projects/useFetchProjects";
import { appPath } from "@routes/app";
import { createTitle } from "@utils/config";
import { ProjectType } from "@utils/ProjectType";
import { translation } from "@utils/translation";
import { useContext } from "react";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";
import Project from "./components/Project";
import useFetchSkills from "@hooks/skills/useFetchSkills";

const Projects = () => {
  const { language } = useContext(AppContext);
  const { projects } = useFetchProjects(language);
  const { skills } = useFetchSkills(language);

  return (
    <Layout permissionNeeded="projects">
      <Helmet>
        <title>{createTitle(translation(language).sidebar.projects.all)}</title>
      </Helmet>
      <h1 className="text-2xl text-primary">
        {translation(language).sidebar.projects.all}
      </h1>
      <BreadCumber
        items={[
          {
            href: "",
            name: translation(language).sidebar.projects.title,
            isLink: false,
          },
          {
            href: appPath.projects.main,
            name: translation(language).sidebar.projects.all,
            isLink: true,
          },
        ]}
      />
      <div className="col-span-12 mt-5">
        <div className="grid gap-2 grid-cols-1 lg:grid-cols-1">
          <div className="bg-tertiary p-4 shadow-lg rounded-lg">
            <div className="flex flex-row justify-between items-center">
              <div>
                <h1 className="font-bold text-base text-primary">
                  {translation(language).sidebar.projects.title}
                </h1>
                <p className="text-sm text-secondary">
                  {translation(language).project.all.description}
                </p>
              </div>
              <Link to={appPath.projects.create} className="text-primary top-0">
                + {translation(language).project.all.add}
              </Link>
            </div>
            <div className="mt-4">
              <div className="flex flex-col">
                <div className="-my-2 overflow-x-auto">
                  <div className="py-2 align-middle inline-block min-w-full">
                    <table className="min-w-full divide-y divide-gray-200">
                      <thead>
                        <tr>
                          <th className="px-6 py-3 bg-secondary text-xs leading-4 font-medium text-primary uppercase tracking-wider">
                            <div className="flex cursor-pointer">
                              <span className="mr-2 uppercase">
                                {translation(language).project.all.table.name}
                              </span>
                            </div>
                          </th>
                          <th className="px-6 py-3 bg-secondary text-xs leading-4 font-medium text-primary uppercase tracking-wider">
                            <div className="flex cursor-pointer">
                              <span className="mr-2 uppercase">
                                {
                                  translation(language).project.all.table
                                    .isVisible.title
                                }
                              </span>
                            </div>
                          </th>
                          <th className="px-6 py-3 bg-secondary text-xs leading-4 font-medium text-primary uppercase tracking-wider">
                            <div className="flex cursor-pointer">
                              <span className="mr-2 uppercase">
                                {translation(language).project.all.table.skills}
                              </span>
                            </div>
                          </th>
                          <th className="px-6 py-3 bg-secondary text-xs leading-4 font-medium text-primary uppercase tracking-wider">
                            <div className="flex cursor-pointer">
                              <span className="mr-2 uppercase">
                                {
                                  translation(language).project.all.table
                                    .actions
                                }
                              </span>
                            </div>
                          </th>
                        </tr>
                      </thead>
                      <tbody className="bg-primary text-primary divide-y divide-gray-200">
                        {projects ? (
                          projects?.length > 0 ? (
                            projects?.map((project: ProjectType) => (
                              <Project
                                key={project._id}
                                project={project}
                                skills={skills}
                              />
                            ))
                          ) : (
                            <tr>
                              <td colSpan={4} className="pt-5">
                                <AlertBox
                                  type="info"
                                  message={
                                    translation(language).general.error
                                      .noDataFound
                                  }
                                />
                              </td>
                            </tr>
                          )
                        ) : (
                          <tr>
                            <td colSpan={4} className="pt-5">
                              <AlertBox
                                type="danger"
                                message={
                                  translation(language).general.error
                                    .somethingWentWrong
                                }
                              />
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Projects;
