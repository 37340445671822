import { executeGetRequest } from "@api/client";
import { apiPath } from "@routes/app";
import { config } from "@utils/config";

export const getCommands = async (lang: string) => {
  const [promise] = executeGetRequest(
    apiPath.commands,
    localStorage.getItem(config.localStorage.sessionToken) || "",
    lang
  );
  const result: any = await promise;
  return result.data;
};

export const getLatestCommand = async (lang: string) => {
  const [promise] = executeGetRequest(
    apiPath.latestCommand,
    localStorage.getItem(config.localStorage.sessionToken) || "",
    lang
  );
  const result: any = await promise;
  return result.data;
};

export const getServers = async (lang: string) => {
  const [promise] = executeGetRequest(
    apiPath.servers,
    localStorage.getItem(config.localStorage.sessionToken) || "",
    lang
  );
  const result: any = await promise;
  return result.data;
};
