import { config } from "@utils/config";
import { UserType } from "@utils/UserType";
import {
  createContext,
  Dispatch,
  ReactChild,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import { getInitialTheme } from "./themeContext";

type Props = {
  initialTheme?: any;
  children: ReactChild[] | ReactChild;
};

interface ContextData {
  theme: string;
  setTheme: Dispatch<SetStateAction<string>>;
  user: UserType;
  setUser: Dispatch<SetStateAction<UserType>>;
  sessionToken: string | null;
  setSessionToken: Dispatch<SetStateAction<string | null>>;
  language: string;
  setLanguage: Dispatch<SetStateAction<string>>;
  isLoading: boolean;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
}

const ContextDefaultValue: ContextData = {
  theme: getInitialTheme(),
  setTheme:
    () =>
    (_value: SetStateAction<string>): void => {
      throw new Error("Function not implemented.");
    },
  user: {} as UserType,
  setUser: (_value: SetStateAction<UserType>): void => {
    throw new Error("Function not implemented.");
  },
  sessionToken: "",
  setSessionToken: (_value: SetStateAction<string | null>): void => {
    throw new Error("Function not implemented.");
  },
  language: "",
  setLanguage: (_value: SetStateAction<string>): void => {
    throw new Error("Function not implemented.");
  },
  isLoading: true,
  setIsLoading: (_value: SetStateAction<boolean>): void => {
    throw new Error("Function not implemented.");
  },
};

export const AppContext = createContext<ContextData>(ContextDefaultValue);

const AppContextProvider = ({ initialTheme, children }: Props) => {
  const [theme, setTheme] = useState(ContextDefaultValue.theme);
  const [user, setUser] = useState(ContextDefaultValue.user);
  const [sessionToken, setSessionToken] = useState(
    ContextDefaultValue.sessionToken
  );
  const [language, setLanguage] = useState(ContextDefaultValue.language);
  const [isLoading, setIsLoading] = useState(ContextDefaultValue.isLoading);

  const rawSetTheme = (theme: string): void => {
    const root = window.document.documentElement;
    const isDark = theme === "dark";

    root.classList.remove(isDark ? "light" : "dark");
    root.classList.add(theme);

    localStorage.setItem(config.localStorage.theme, theme);
  };

  if (initialTheme) {
    rawSetTheme(initialTheme);
  }

  useEffect(() => {
    rawSetTheme(theme);
  }, [theme]);

  return (
    <AppContext.Provider
      value={{
        theme,
        setTheme,
        user,
        setUser,
        sessionToken,
        setSessionToken,
        language,
        setLanguage,
        isLoading,
        setIsLoading,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export default AppContextProvider;
