import { AppContext } from "@components/features/appContext/AppContext";
import BreadCumber from "@components/features/breadCumber/BreadCumber";
import AlertBox from "@components/primitives/alertBox/AlertBox";
import Layout from "@components/primitives/layout/Layout";
import { appPath } from "@routes/app";
import { createTitle } from "@utils/config";
import { translation } from "@utils/translation";
import { useContext } from "react";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";
import EventElement from "./components/EventElement";
import { EventType } from "@utils/EventType";
import useFetchEvents from "@hooks/events/useFetchEvents";

const Events = () => {
  const { language } = useContext(AppContext);
  const { events } = useFetchEvents(language);

  return (
    <Layout permissionNeeded="events">
      <Helmet>
        <title>{createTitle(translation(language).sidebar.events.all)}</title>
      </Helmet>
      <h1 className="text-2xl text-primary">
        {translation(language).sidebar.events.all}
      </h1>
      <BreadCumber
        items={[
          {
            href: "",
            name: translation(language).sidebar.events.title,
            isLink: false,
          },
          {
            href: appPath.events.main,
            name: translation(language).sidebar.events.all,
            isLink: true,
          },
        ]}
      />
      <div className="col-span-12 mt-5">
        <div className="grid gap-2 grid-cols-1 lg:grid-cols-1">
          <div className="bg-tertiary p-4 shadow-lg rounded-lg">
            <div className="flex flex-row justify-between items-center">
              <div>
                <h1 className="font-bold text-base text-primary">
                  {translation(language).sidebar.events.title}
                </h1>
                <p className="text-sm text-secondary">
                  {translation(language).events.all.description}
                </p>
              </div>
              <Link to={appPath.events.create} className="text-primary top-0">
                + {translation(language).events.all.add}
              </Link>
            </div>
            <div className="mt-4">
              <div className="flex flex-col">
                <div className="-my-2 overflow-x-auto">
                  <div className="py-2 align-middle inline-block min-w-full">
                    <table className="min-w-full divide-y divide-gray-200">
                      <thead>
                        <tr>
                          <th className="px-6 py-3 bg-secondary text-xs leading-4 font-medium text-primary uppercase tracking-wider">
                            <div className="flex cursor-pointer">
                              <span className="mr-2 uppercase">
                                {translation(language).events.all.table.title}
                              </span>
                            </div>
                          </th>
                          <th className="px-6 py-3 bg-secondary text-xs leading-4 font-medium text-primary uppercase tracking-wider">
                            <div className="flex cursor-pointer">
                              <span className="mr-2 uppercase">
                                {
                                  translation(language).events.all.table
                                    .location
                                }
                              </span>
                            </div>
                          </th>
                          <th className="px-6 py-3 bg-secondary text-xs leading-4 font-medium text-primary uppercase tracking-wider">
                            <div className="flex cursor-pointer">
                              <span className="mr-2 uppercase">
                                {
                                  translation(language).events.all.table
                                    .startDate
                                }
                              </span>
                            </div>
                          </th>
                          <th className="px-6 py-3 bg-secondary text-xs leading-4 font-medium text-primary uppercase tracking-wider">
                            <div className="flex cursor-pointer">
                              <span className="mr-2 uppercase">
                                {translation(language).events.all.table.endDate}
                              </span>
                            </div>
                          </th>
                          <th className="px-6 py-3 bg-secondary text-xs leading-4 font-medium text-primary uppercase tracking-wider">
                            <div className="flex cursor-pointer">
                              <span className="mr-2 uppercase">
                                {translation(language).events.all.table.actions}
                              </span>
                            </div>
                          </th>
                        </tr>
                      </thead>
                      <tbody className="bg-primary text-primary divide-y divide-gray-200">
                        {events ? (
                          events?.length > 0 ? (
                            events?.map((event: EventType) => (
                              <EventElement key={event._id} event={event} />
                            ))
                          ) : (
                            <tr>
                              <td colSpan={5} className="pt-5">
                                <AlertBox
                                  type="info"
                                  message={
                                    translation(language).general.error
                                      .noDataFound
                                  }
                                />
                              </td>
                            </tr>
                          )
                        ) : (
                          <tr>
                            <td colSpan={5} className="pt-5">
                              <AlertBox
                                type="danger"
                                message={
                                  translation(language).general.error
                                    .somethingWentWrong
                                }
                              />
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Events;
