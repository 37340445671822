import { ServerType } from "@utils/ServerType";

type Props = {
  server: ServerType;
};

const ServerIcon = ({ server }: Props) => {
  if (server.icon)
    return (
      <img
        src={server.icon}
        alt={server.name}
        className="h-12 w-12 rounded-full"
      />
    );

  return (
    <div className="h-12 w-12 rounded-full bg-gray-600  flex items-center justify-center">
      <span className="text-white">{server.nameAcronym}</span>
    </div>
  );
};

export default ServerIcon;
