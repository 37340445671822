import { useState } from "react";

type Props = {
  title: string;
  name: string;
  required?: boolean;
  value?: number;
  step: number;
  min: number;
  max: number;
};

const InputRange = ({
  title,
  name,
  required = true,
  value,
  min,
  max,
  step,
}: Props) => {
  const [percentageState, setPercentageState] = useState(value || 50);

  return (
    <div className="border focus-within:border-blue-500 focus-within:text-blue-500 bg-quaternary transition-all duration-500 relative rounded p-1 h-fit">
      <div className="-mt-7 absolute tracking-wider uppercase text-xs">
        <p>
          <label htmlFor={name} className="text-secondary px-1">
            {title}
            {required ? "*" : null}
          </label>
        </p>
      </div>
      <div className="flex items-center gap-5">
        <input
          id={name}
          name={name}
          step={step}
          min={min}
          max={max}
          onChange={(e: any) => setPercentageState(e.target.value)}
          defaultValue={value || 50}
          autoComplete="false"
          tabIndex={0}
          type="range"
          className="py-2 px-2 text-primary outline-none block h-full w-full bg-transparent"
        />
        <p className="text-primary">{percentageState}%</p>
      </div>
    </div>
  );
};

export default InputRange;
