import { AppContext } from "@components/features/appContext/AppContext";
import BreadCumber from "@components/features/breadCumber/BreadCumber";
import InputField from "@components/primitives/form/InputField";
import Layout from "@components/primitives/layout/Layout";
import Toast from "@components/primitives/toast/Toast";
import useFetchEvents from "@hooks/events/useFetchEvents";
import usePutEvent from "@hooks/events/usePutEvent";
import { appPath, createEventPath } from "@routes/app";
import { createTitle } from "@utils/config";
import { dateToInputDate } from "@utils/dateToInputDate";
import { EventType } from "@utils/EventType";
import { translation } from "@utils/translation";
import { useContext } from "react";
import { Helmet } from "react-helmet-async";
import { Link, useParams } from "react-router-dom";
import Swal, { SweetAlertResult } from "sweetalert2";

const EventEdit = () => {
  const { id } = useParams();
  const { language } = useContext(AppContext);
  const { events } = useFetchEvents(language);

  // TODO: Wanneer het niet gevonden is, redirect naar /main
  const event = events?.find(
    (event: EventType) => event._id === id
  ) as EventType;

  const { mutate } = usePutEvent(event?._id, language);

  const handleSubmit = (e: any) => {
    e.preventDefault();

    Swal.fire({
      title: translation(language).general.notification.prompt.title,
      text: translation(language).general.notification.prompt.text,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText:
        translation(language).general.notification.prompt.confirm,
      cancelButtonText:
        translation(language).general.notification.prompt.cancel,
    }).then((result: SweetAlertResult<any>) => {
      if (result.value) {
        const formData = new FormData(e.target);

        const data = {
          title: formData.get("title"),
          location: formData.get("location"),
          startDate: formData.get("startDate"),
          endDate: formData.get("endDate"),
        };

        if (
          data.title === "" ||
          data.location === "" ||
          data.startDate === "" ||
          data.endDate === ""
        ) {
          return Toast("error", translation(language).general.error.emptyFields);
        }

        if (data.startDate === null || data.endDate === null) return;

        if (data.startDate > data.endDate) {
          return Toast(
            "error",
            translation(language).general.error.starDateLaterThanEndDate
          );
        }

        mutate(data);
        Toast("success", translation(language).general.success.updated);
      } else {
        Toast("error", translation(language).general.not.updated);
      }
    });
  };

  return (
    <Layout permissionNeeded="events">
      <Helmet>
        <title>{createTitle(event?.title)}</title>
      </Helmet>
      <h1 className="text-2xl text-primary">{event?.title}</h1>
      <BreadCumber
        items={[
          {
            href: "",
            name: translation(language).sidebar.events.title,
            isLink: false,
          },
          {
            href: appPath.events.main,
            name: translation(language).sidebar.events.all,
            isLink: true,
          },
          {
            href: createEventPath(event?._id),
            name: event?._id,
            isLink: true,
          },
        ]}
      />

      <form className="relative py-6" onSubmit={(e: any) => handleSubmit(e)}>
        <div className="grid lg:grid-cols-2 gap-9">
          <InputField
            title={translation(language).events.all.table.title}
            name="title"
            value={event?.title}
          />
          <InputField
            title={translation(language).events.all.table.location}
            name="location"
            value={event?.location}
          />
          <InputField
            title={translation(language).events.all.table.startDate}
            type="datetime-local"
            name="startDate"
            value={dateToInputDate(event?.startDate)}
          />
          <InputField
            title={translation(language).events.all.table.endDate}
            type="datetime-local"
            name="endDate"
            value={dateToInputDate(event?.endDate)}
          />
        </div>
        <div className="border-t mt-6 pt-3 flex flex-row gap-5">
          <Link
            to={appPath.events.main}
            className="rounded text-gray-100 p-2 bg-red-500 hover:shadow-inner hover:bg-red-700 transition-all duration-300"
          >
            {translation(language).general.actions.cancel}
          </Link>
          <button className="rounded text-gray-100 p-2 bg-blue-500 hover:shadow-inner hover:bg-blue-700 transition-all duration-300">
            {translation(language).general.actions.save}
          </button>
        </div>
      </form>
    </Layout>
  );
};

export default EventEdit;
