type Props = {
  title: string;
  src: string;
  isRounded?: boolean;
};

const PreviewImage = ({ title, src, isRounded }: Props) => (
  <div className="border bg-quaternary transition-all duration-500 relative rounded p-1 w-fit h-fit">
    <div className="-mt-6 absolute tracking-wider uppercase text-xs">
      <p>
        <label htmlFor={title} className="text-secondary px-1">
          {title}
        </label>
      </p>
    </div>
    <div className="py-3 px-3 text-primary flex flex-col items-center justify-center h-full">
      <img
        className={`w-auto h-32 ${isRounded && "rounded-full"}`}
        src={src}
        alt={title}
      />
    </div>
  </div>
);

export default PreviewImage;
