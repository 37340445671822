import { AppContext } from "@components/features/appContext/AppContext";
import BreadCumber from "@components/features/breadCumber/BreadCumber";
import InputField from "@components/primitives/form/InputField";
import InputSelect from "@components/primitives/form/InputSelect";
import PreviewImage from "@components/primitives/form/PreviewImage";
import Layout from "@components/primitives/layout/Layout";
import Toast from "@components/primitives/toast/Toast";
import useFetchContacts from "@hooks/contacts/useFetchContacts";
import usePutContact from "@hooks/contacts/usePutContact";
import { appPath, createContactPath } from "@routes/app";
import { createTitle } from "@utils/config";
import { ContactType } from "@utils/ContactType";
import { translation } from "@utils/translation";
import { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Link, useParams } from "react-router-dom";
import Swal, { SweetAlertResult } from "sweetalert2";

const ContactEdit = () => {
  const { id } = useParams();
  const { language } = useContext(AppContext);
  const { contacts } = useFetchContacts(language);

  // TODO: Wanneer het niet gevonden is, redirect naar /main
  const contact = contacts?.find(
    (contact: ContactType) => contact._id === id
  ) as ContactType;

  const [previewPhoto, setPreviewPhoto] = useState("");

  useEffect(() => {
    if (!contact) return;

    setPreviewPhoto(contact?.icon);
  }, [contact]);

  const { mutate } = usePutContact(contact?._id, language);

  const handleSubmit = (e: any) => {
    e.preventDefault();

    Swal.fire({
      title: translation(language).general.notification.prompt.title,
      text: translation(language).general.notification.prompt.text,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText:
        translation(language).general.notification.prompt.confirm,
      cancelButtonText:
        translation(language).general.notification.prompt.cancel,
    }).then((result: SweetAlertResult<any>) => {
      if (result.value) {
        const formData = new FormData(e.target);
        const data = {
          name: formData.get("name"),
          icon: formData.get("icon"),
          url: formData.get("url"),
          type: formData.get("type"),
          isVisible: formData.get("visible") === "true",
        };

        mutate(data);
        Toast("success", translation(language).general.success.updated);
      } else {
        Toast("error", translation(language).general.not.updated);
      }
    });
  };

  return (
    <Layout permissionNeeded="contacts">
      <Helmet>
        <title>{createTitle(contact?.name)}</title>
      </Helmet>
      <h1 className="text-2xl text-primary">{contact?.name}</h1>
      <BreadCumber
        items={[
          {
            href: "",
            name: translation(language).sidebar.contacts.title,
            isLink: false,
          },
          {
            href: appPath.contacts.main,
            name: translation(language).sidebar.contacts.all,
            isLink: true,
          },
          {
            href: createContactPath(contact?._id),
            name: contact?._id,
            isLink: true,
          },
        ]}
      />

      <form className="relative py-6" onSubmit={(e: any) => handleSubmit(e)}>
        <div className="grid lg:grid-cols-2 gap-9">
          <InputField
            title={translation(language).contacts.all.table.name}
            name="name"
            value={contact?.name}
          />
          <InputSelect
            title={translation(language).contacts.all.table.isVisible.title}
            name="visible"
            value={contact?.isVisible}
            options={[
              { label: translation(language).general.text.yes, value: true },
              { label: translation(language).general.text.no, value: false },
            ]}
          />
          <InputSelect
            title={translation(language).contacts.all.table.type}
            name="type"
            value={contact?.type}
            options={[
              { label: "main", value: "main" },
              { label: "music", value: "music" },
            ]}
          />
          <InputField
            title={translation(language).contacts.all.table.url}
            name="url"
            value={contact?.url}
          />
          <InputField
            title={translation(language).contacts.all.table.icon}
            name="icon"
            onBlur={(e: any) => setPreviewPhoto(e.target.value)}
            value={contact?.icon}
          />
          <PreviewImage title="Preview" src={previewPhoto} isRounded={true} />
        </div>
        <div className="border-t mt-6 pt-3 flex flex-row gap-5">
          <Link
            to={appPath.contacts.main}
            className="rounded text-gray-100 p-2 bg-red-500 hover:shadow-inner hover:bg-red-700 transition-all duration-300"
          >
            {translation(language).general.actions.cancel}
          </Link>
          <button className="rounded text-gray-100 p-2 bg-blue-500 hover:shadow-inner hover:bg-blue-700 transition-all duration-300">
            {translation(language).general.actions.save}
          </button>
        </div>
      </form>
    </Layout>
  );
};

export default ContactEdit;
