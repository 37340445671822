import { ServerType } from "@utils/ServerType";
import ServerIcon from "./ServerIcon";

type Props = {
  server: ServerType;
};

const Server = ({ server }: Props) => (
  <tr className="hover:bg-quaternary">
    <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5">
      {server.guildId}
    </td>
    <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 flex flex-row items-center gap-5">
      <ServerIcon server={server} />
      {server.name}
    </td>
    <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 ">
      {server.ownerId}
    </td>
    <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5">
      <img
        className="h-12 w-auto"
        alt={server.prefferdLocale}
        src={`https://flagsapi.com/${
          server.prefferdLocale.split("-")[1]
            ? server.prefferdLocale.split("-")[1].toUpperCase()
            : server.prefferdLocale.split("-")[0].toUpperCase()
        }/flat/64.png`}
      />
    </td>
  </tr>
);

export default Server;
