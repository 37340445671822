import {
  executeDeleteRequest,
  executeGetRequest,
  executePostRequest,
  executePutRequest,
} from "@api/client";
import { apiPath, createEventWithIdPath } from "@routes/app";
import { config } from "@utils/config";

export const getEvents = async (lang: string) => {
  const [promise] = executeGetRequest(
    apiPath.events,
    localStorage.getItem(config.localStorage.sessionToken) || "",
    lang
  );
  const result: any = await promise;
  return result.data;
};

export const postEvent = async (data: any, lang: string) => {
  const [promise] = executePostRequest(
    apiPath.events,
    data,
    lang,
    localStorage.getItem(config.localStorage.sessionToken) || ""
  );
  const result: any = await promise;
  return result.data;
};

export const putEvent = async (id: string, data: any, lang: string) => {
  const [promise] = executePutRequest(
    createEventWithIdPath(id),
    data,
    localStorage.getItem(config.localStorage.sessionToken) || "",
    lang
  );
  const result: any = await promise;
  return result.data;
};

export const deleteEvent = async (id: any, lang: string) => {
  const [promise] = executeDeleteRequest(
    createEventWithIdPath(id),
    localStorage.getItem(config.localStorage.sessionToken) || "",
    lang
  );
  const result: any = await promise;
  return result.data;
};
