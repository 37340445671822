import {
  executeDeleteRequest,
  executeGetRequest,
  executePostRequest,
  executePutRequest,
} from "@api/client";
import { apiPath, createSkillWithIdPath } from "@routes/app";
import { config } from "@utils/config";

export const getSkills = async (lang: string) => {
  const [promise] = executeGetRequest(
    apiPath.skills,
    localStorage.getItem(config.localStorage.sessionToken) || "",
    lang
  );
  const result: any = await promise;
  return result.data;
};

export const postSkill = async (data: any, lang: string) => {
  const [promise] = executePostRequest(
    apiPath.skills,
    data,
    lang,
    localStorage.getItem(config.localStorage.sessionToken) || ""
  );
  const result: any = await promise;
  return result.data;
};

export const putSkill = async (id: string, data: any, lang: string) => {
  const [promise] = executePutRequest(
    createSkillWithIdPath(id),
    data,
    localStorage.getItem(config.localStorage.sessionToken) || "",
    lang
  );
  const result: any = await promise;
  return result.data;
};

export const deleteSkill = async (id: any, lang: string) => {
  const [promise] = executeDeleteRequest(
    createSkillWithIdPath(id),
    localStorage.getItem(config.localStorage.sessionToken) || "",
    lang
  );
  const result: any = await promise;
  return result.data;
};
