import { executeGetRequest } from "@api/client";
import { apiPath } from "@routes/app";
import { config } from "@utils/config";

export const getContainers = async (lang: string) => {
  const [promise] = executeGetRequest(
    apiPath.containers,
    localStorage.getItem(config.localStorage.sessionToken) || "",
    lang
  );
  const result: any = await promise;
  return result.data;
};
