import { useMutation } from "react-query";
import { putProject } from "@repository/projectRepository";

const usePutProject = (id: string, lang: string) => {
  // TODO: Fix automatic update
  const mutation = useMutation((data: void) => {
    return putProject(id, data, lang);
  });

  const { mutate, isLoading, isError, data, error }: any = mutation;
  return {
    mutate,
    isLoading,
    isError,
    data,
    errorMessage: error?.response.data,
  };
};

export default usePutProject;
