import { useEffect } from "react";

// Hook to detect when the user clicks outside of a component
export const useOutsideAlerter = (ref: any, callBack: any) => {
  useEffect(() => {
    const handleClickOutside = (event: any) => {
      // if the user clicks outside of the component, call the callback
      if (ref.current && !ref.current.contains(event.target)) {
        callBack();
      }
    };

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref]);
};
