import { useMutation } from "react-query";
import { loginUser } from "@repository/entriesRepository";

const usePostLogin = (lang: string) => {
  const mutation = useMutation((data: void) => {
    return loginUser(data, lang);
  });

  const { mutate, isLoading, isError, data, error }: any = mutation;
  return {
    mutate,
    isLoading,
    isError,
    data,
    errorMessage: error?.response.data,
  };
};

export default usePostLogin;
