import { AppContext } from "@components/features/appContext/AppContext";
import { ReactComponent as AdminSVG } from "@assets/images/admin.svg";
import Toast from "@components/primitives/toast/Toast";
import { createUserPath } from "@routes/app";
import { UserType } from "@utils/UserType";
import { translation } from "@utils/translation";
import classNames from "classnames";
import { useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import Swal, { SweetAlertResult } from "sweetalert2";
import useDeleteUser from "@hooks/users/useDeleteUser";

type Props = {
  user: UserType;
};

const User = ({ user }: Props) => {
  const { id, username, permissions, isAdmin, isBlocked } = user;
  const { language, user: sessionUser } = useContext(AppContext);
  const { mutate: deleteUser, errorMessage, data } = useDeleteUser(language);

  useEffect(() => {
    if (!errorMessage) return;

    Toast("error", errorMessage);
  }, [errorMessage]);

  useEffect(() => {
    if (!data) return;

    Toast("success", data);
  }, [data]);

  const handleDelete = async () => {
    Swal.fire({
      title: translation(language).general.notification.prompt.title,
      text: translation(language).general.notification.prompt.text,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText:
        translation(language).general.notification.prompt.confirm,
      cancelButtonText:
        translation(language).general.notification.prompt.cancel,
    }).then(async (result: SweetAlertResult<any>) => {
      if (result.value) {
        await deleteUser({ id: id });
        Toast("success", translation(language).general.success.deleted);
      } else {
        Toast("error", translation(language).general.not.deleted);
      }
    });
  };

  const visibleClassName = classNames(
    "px-2 py-1 font-semibold leading-tight rounded-sm w-full text-center",
    {
      "text-green-700 bg-green-100": !isBlocked,
      "text-red-700 bg-red-100": isBlocked,
    }
  );

  const convertPermissionsToText = (permissions: any) => {
    const permissionText = Object.entries(permissions).map(([key, value]) => {
      const permissionClassName = classNames(
        "px-2 py-1 font-semibold leading-tight rounded-sm text-center",
        {
          "text-green-700 bg-green-100": value,
          "text-red-700 bg-red-100": !value,
        }
      );
      return (
        <span key={key} className={permissionClassName}>
          {key}
        </span>
      );
    });

    return <p className="flex gap-5">{permissionText}</p>;
  };

  return (
    <tr className="hover:bg-quaternary">
      <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5">
        <p className="flex items-center gap-2">
          {isAdmin && <AdminSVG className="w-5 text-yellow-500" />}
          {username}
        </p>
      </td>
      <td className="px-4 py-4 whitespace-no-wrap text-sm leading-5">
        <span className={visibleClassName}>
          {isBlocked
            ? translation(language).users.all.table.state.blocked
            : translation(language).users.all.table.state.notBlocked}
        </span>
      </td>
      <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 overflow-scroll max-w-[450px]">
        <div>{convertPermissionsToText(permissions)}</div>
      </td>
      <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5">
        {sessionUser.id !== user.id && !isAdmin && (
          <div className="flex space-x-4">
            <Link
              to={createUserPath(id)}
              className="text-blue-500 hover:text-blue-600 flex flex-row"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="w-5 h-5 mr-1"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
                />
              </svg>
              <p>{translation(language).general.actions.edit}</p>
            </Link>
            <button
              onClick={() => handleDelete()}
              className="text-red-500 hover:text-red-600 flex flex-row"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="w-5 h-5 mr-1 ml-3"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                />
              </svg>
              <p>{translation(language).general.actions.delete}</p>
            </button>
          </div>
        )}
      </td>
    </tr>
  );
};

export default User;
