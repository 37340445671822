import { nl_NL } from "./lang/nl_NL";
import { en_US } from "./lang/en_US";

// Definitions for the translation
export const languageOptions = [
  { value: "nl_NL", label: "🇳🇱 Nederlands" },
  { value: "en_US", label: "🇬🇧 English" },
];

// Returns the language for the current browser
export const translation = (language: string) => {
  switch (language) {
    case "nl_NL":
      return { ...nl_NL };
    case "en_US":
      return { ...en_US };
    default:
      return { ...nl_NL };
  }
};
