import Toast from "@components/primitives/toast/Toast";
import { appPath, baseURL } from "@routes/app";
import axios, { AxiosResponse } from "axios";

const defaultTimeoutLength = 4000; // 4 seconds

const axiosInstance = axios.create({
  baseURL, // baseURL is defined in @routes/app
  headers: {
    Accept: "application/json",
    "Cache-Control": "no-cache",
  },
});

axiosInstance.interceptors.response.use(
  (response: AxiosResponse) => {
    return response;
  },
  (error: any) => {
    if (error.response) {
      const { status, data } = error.response;
      if (status === 440) {
        window.location.href = appPath.login;
        Toast("error", data);
      }
    }
    return error;
  }
);

export const executeGetRequest = (
  path: string,
  token: string,
  lang: string
) => {
  const promise = axiosInstance.get(path, {
    timeout: defaultTimeoutLength,
    headers: {
      Authorization: `Bearer ${token}`,
      Language: lang,
    },
  });

  return [promise];
};

export const executePostRequest = (
  path: string,
  data: any,
  lang: string,
  token?: any
) => {
  const promise = axiosInstance.post(path, data, {
    timeout: defaultTimeoutLength,
    headers: {
      Authorization: `Bearer ${token}`,
      Language: lang,
    },
  });

  return [promise];
};

export const executePutRequest = (
  path: string,
  data: any,
  token: string,
  lang: string
) => {
  const promise = axiosInstance.put(path, data, {
    timeout: defaultTimeoutLength,
    headers: {
      Authorization: `Bearer ${token}`,
      Language: lang,
    },
  });

  return [promise];
};

export const executeDeleteRequest = (
  path: string,
  token: string,
  lang: string
) => {
  const promise = axiosInstance.delete(path, {
    timeout: defaultTimeoutLength,
    headers: {
      Authorization: `Bearer ${token}`,
      Language: lang,
    },
  });

  return [promise];
};
