import { getSkills } from "@repository/skillsRepository";
import { useQuery } from "react-query";

const useFetchSkills = (lang: string) => {
  const { data, isLoading, isError } = useQuery(
    ["skills"],
    () => getSkills(lang),
    {
      refetchInterval: 1000 * 60 * 60,
    }
  );
  return { skills: data, isLoading, isError };
};

export default useFetchSkills;
