import { AppContext } from "@components/features/appContext/AppContext";
import BreadCumber from "@components/features/breadCumber/BreadCumber";
import Layout from "@components/primitives/layout/Layout";
import { appPath } from "@routes/app";
import { createTitle } from "@utils/config";
import { translation } from "@utils/translation";
import { useContext } from "react";
import { Helmet } from "react-helmet-async";
import Container from "./components/Container";

const Contacts = () => {
  const { language } = useContext(AppContext);

  return (
    <Layout permissionNeeded="contacts">
      <Helmet>
        <title>{createTitle(translation(language).sidebar.contacts.all)}</title>
      </Helmet>
      <h1 className="text-2xl text-primary">
        {translation(language).sidebar.contacts.all}
      </h1>
      <BreadCumber
        items={[
          {
            href: "",
            name: translation(language).sidebar.contacts.title,
            isLink: false,
          },
          {
            href: appPath.contacts.main,
            name: translation(language).sidebar.contacts.all,
            isLink: true,
          },
        ]}
      />
      <div className="col-span-12 mt-5">
        <div className="grid gap-5 grid-cols-1 lg:grid-cols-1">
          <Container type="main" />
          <Container type="music" />
        </div>
      </div>
    </Layout>
  );
};

export default Contacts;
