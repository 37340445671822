import { useMutation, useQueryClient } from "react-query";
import { postFile } from "@repository/filesRepository";

const usePostFile = (parent: any, lang: string) => {
  const queryClient = useQueryClient();
  const mutation = useMutation(
    (data: any) => {
      let formData = new FormData();

      formData.append("file", data.file);
      formData.append("parent", data.parent);
      formData.append("size", data.size);
      formData.append("fileName", data.fileName);
      formData.append("extension", data.extension);

      return postFile(formData, lang);
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries("files", parent);
      },
    }
  );

  const { mutate, isLoading, isError, data, error }: any = mutation;
  return {
    mutate,
    isLoading,
    isError,
    data,
    errorMessage: error?.response.data,
  };
};

export default usePostFile;
