import { AppContext } from "@components/features/appContext/AppContext";
import BreadCumber from "@components/features/breadCumber/BreadCumber";
import InputCheckboxes from "@components/primitives/form/InputCheckboxes";
import InputField from "@components/primitives/form/InputField";
import InputSelect from "@components/primitives/form/InputSelect";
import InputTextArea from "@components/primitives/form/InputTextArea";
import PreviewImage from "@components/primitives/form/PreviewImage";
import Layout from "@components/primitives/layout/Layout";
import Toast from "@components/primitives/toast/Toast";
import useFetchProjects from "@hooks/projects/useFetchProjects";
import usePutProject from "@hooks/projects/usePutProject";
import useFetchSkills from "@hooks/skills/useFetchSkills";
import { appPath, createProjectPath } from "@routes/app";
import { createTitle } from "@utils/config";
import { ProjectType } from "@utils/ProjectType";
import { SkillType } from "@utils/SkillType";
import { translation } from "@utils/translation";
import { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Link, useParams } from "react-router-dom";
import Swal, { SweetAlertResult } from "sweetalert2";

const ProjectEdit = () => {
  const { id } = useParams();
  const { language } = useContext(AppContext);
  const { projects } = useFetchProjects(language);
  const { skills } = useFetchSkills(language);

  // TODO: Wanneer het niet gevonden is, redirect naar /main
  const project = projects?.find(
    (project: ProjectType) => project._id === id
  ) as ProjectType;

  const [previewPhoto, setPreviewPhoto] = useState("");

  useEffect(() => {
    if (!project) return;

    setPreviewPhoto(project?.photo);
  }, [project]);

  const { mutate } = usePutProject(project?._id, language);

  const handleSubmit = (e: any) => {
    e.preventDefault();

    Swal.fire({
      title: translation(language).general.notification.prompt.title,
      text: translation(language).general.notification.prompt.text,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText:
        translation(language).general.notification.prompt.confirm,
      cancelButtonText:
        translation(language).general.notification.prompt.cancel,
    }).then((result: SweetAlertResult<any>) => {
      if (result.value) {
        const formData = new FormData(e.target);
        const data = {
          name: formData.get("name"),
          description: formData.get("description"),
          url: formData.get("url"),
          photo: formData.get("photo"),
          isVisible: formData.get("visible") === "true",
        };

        mutate(data);
        Toast("success", translation(language).general.success.updated);
      } else {
        Toast("error", translation(language).general.not.updated);
      }
    });
  };

  return (
    <Layout permissionNeeded="projects">
      <Helmet>
        <title>{createTitle(project?.name)}</title>
      </Helmet>
      <h1 className="text-2xl text-primary">{project?.name}</h1>
      <BreadCumber
        items={[
          {
            href: "",
            name: translation(language).sidebar.projects.title,
            isLink: false,
          },
          {
            href: appPath.projects.main,
            name: translation(language).sidebar.projects.all,
            isLink: true,
          },
          {
            href: createProjectPath(project?._id),
            name: project?._id,
            isLink: true,
          },
        ]}
      />

      <form className="relative py-6" onSubmit={(e: any) => handleSubmit(e)}>
        <div className="grid lg:grid-cols-2 gap-9">
          <InputField
            title={translation(language).project.all.table.name}
            name="name"
            value={project?.name}
          />
          <InputTextArea
            title={translation(language).project.all.table.description}
            name="description"
            value={project?.description}
          />
          <InputField
            title={translation(language).project.all.table.url}
            name="url"
            value={project?.url}
          />
          <InputField
            title={translation(language).project.all.table.photo}
            name="photo"
            onBlur={(e: any) => setPreviewPhoto(e.target.value)}
            value={project?.photo}
          />
          <InputSelect
            title={translation(language).project.all.table.isVisible.title}
            name="visible"
            value={project?.isVisible}
            options={[
              { label: translation(language).general.text.yes, value: true },
              { label: translation(language).general.text.no, value: false },
            ]}
          />
          <div></div>
          <InputCheckboxes
            title={translation(language).project.all.table.skills}
            name="visible"
            options={
              skills
                ? skills?.map((skill: SkillType) => ({
                    label: skill.name,
                    checked: project.skills.includes(skill.name),
                  }))
                : []
            }
          />
          <PreviewImage title="Preview" src={previewPhoto} />
        </div>
        <div className="border-t mt-6 pt-3 flex flex-row gap-5">
          <Link
            to={appPath.projects.main}
            className="rounded text-gray-100 p-2 bg-red-500 hover:shadow-inner hover:bg-red-700 transition-all duration-300"
          >
            {translation(language).general.actions.cancel}
          </Link>
          <button className="rounded text-gray-100 p-2 bg-blue-500 hover:shadow-inner hover:bg-blue-700 transition-all duration-300">
            {translation(language).general.actions.save}
          </button>
        </div>
      </form>
    </Layout>
  );
};

export default ProjectEdit;
