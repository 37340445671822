import { AppContext } from "@components/features/appContext/AppContext";
import AlertBox from "@components/primitives/alertBox/AlertBox";
import useFetchContacts from "@hooks/contacts/useFetchContacts";
import { appPath } from "@routes/app";
import { ContactType } from "@utils/ContactType";
import { translation } from "@utils/translation";
import { useContext } from "react";
import { Link } from "react-router-dom";
import Contact from "./Contact";

type ContainerProps = {
  type: "music" | "main";
};

function Container({ type }: ContainerProps) {
  const { language } = useContext(AppContext);
  const { contacts } = useFetchContacts(language);

  const url = {
    main: "www.martvanweeghel.nl",
    music: "music.martvanweeghel.nl",
  };

  return (
    <div className="bg-tertiary p-4 shadow-lg rounded-lg">
      <div className="flex flex-row justify-between items-center">
        <div>
          <h1 className="font-bold text-base text-primary">{url[type]}</h1>
          <p className="text-sm text-secondary">
            {translation(language).contacts.all.description[type]}
          </p>
        </div>
        <Link to={appPath.contacts.create} className="text-primary top-0">
          + {translation(language).contacts.all.addContact}
        </Link>
      </div>
      <div className="mt-4">
        <div className="flex flex-col">
          <div className="-my-2 overflow-x-auto">
            <div className="py-2 align-middle inline-block min-w-full">
              <table className="min-w-full divide-y divide-gray-200">
                <thead>
                  <tr>
                    <th className="px-6 py-3 bg-secondary text-xs leading-4 font-medium text-primary uppercase tracking-wider">
                      <div className="flex cursor-pointer">
                        <span className="mr-2 uppercase">
                          {translation(language).contacts.all.table.contact}
                        </span>
                      </div>
                    </th>
                    <th className="px-6 py-3 bg-secondary text-xs leading-4 font-medium text-primary uppercase tracking-wider">
                      <div className="flex cursor-pointer">
                        <span className="mr-2 uppercase">
                          {translation(language).contacts.all.table.type}
                        </span>
                      </div>
                    </th>
                    <th className="px-6 py-3 bg-secondary text-xs leading-4 font-medium text-primary uppercase tracking-wider">
                      <div className="flex cursor-pointer">
                        <span className="mr-2 uppercase">
                          {
                            translation(language).contacts.all.table.isVisible
                              .title
                          }
                        </span>
                      </div>
                    </th>
                    <th className="px-6 py-3 bg-secondary text-xs leading-4 font-medium text-primary uppercase tracking-wider">
                      <div className="flex cursor-pointer">
                        <span className="mr-2 uppercase">
                          {translation(language).general.text.actions}
                        </span>
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-primary text-primary divide-y divide-gray-200">
                  {contacts ? (
                    contacts?.length > 0 ? (
                      contacts
                        ?.filter((c: ContactType) => c.type === type)
                        .map((contact: ContactType) => (
                          <Contact key={contact._id} contact={contact} />
                        ))
                    ) : (
                      <tr>
                        <td colSpan={4} className="pt-5">
                          <AlertBox
                            type="info"
                            message={
                              translation(language).general.error.noDataFound
                            }
                          />
                        </td>
                      </tr>
                    )
                  ) : (
                    <tr>
                      <td colSpan={4} className="pt-5">
                        <AlertBox
                          type="danger"
                          message={
                            translation(language).general.error
                              .somethingWentWrong
                          }
                        />
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Container;
