import { useMutation, useQueryClient } from "react-query";
import { postFolder } from "@repository/filesRepository";

const usePostFolder = (parent: any, lang: string) => {
  const queryClient = useQueryClient();
  const mutation = useMutation(
    (data: any) => {
      const postData = {
        parent,
        ...data,
      };
      return postFolder(postData, lang);
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries("files", parent);
      },
    }
  );

  const { mutate, isLoading, isError, data, error }: any = mutation;
  return {
    mutate,
    isLoading,
    isError,
    data,
    errorMessage: error?.response.data,
  };
};

export default usePostFolder;
