import ImageSVG from "@assets/files/image.png";
import FolderSVG from "@assets/files/folder.png";
import ZipSVG from "@assets/files/zip.png";
import FileSVG from "@assets/files/file.png";
import MusicSVG from "@assets/files/music.png";
import VideoSVG from "@assets/files/video.png";
import PdfSVG from "@assets/files/pdf.png";
import DocSVG from "@assets/files/doc.png";
import XlsSVG from "@assets/files/xls.png";
import PptSVG from "@assets/files/ppt.png";

export const getIcon = (extension: any) => {
  // Switch on the extension to get the correct icon
  switch (extension) {
    case "png":
      return ImageSVG;
    case "jpg":
      return ImageSVG;
    case "jpeg":
      return ImageSVG;
    case "gif":
      return ImageSVG;
    case "svg":
      return ImageSVG;
    case "ico":
      return ImageSVG;
    case "mp3":
      return MusicSVG;
    case "wav":
      return MusicSVG;
    case "mp4":
      return VideoSVG;
    case "avi":
      return VideoSVG;
    case "mov":
      return VideoSVG;
    case "webm":
      return VideoSVG;
    case "pdf":
      return PdfSVG;
    case "doc":
      return DocSVG;
    case "docx":
      return DocSVG;
    case "xls":
      return XlsSVG;
    case "xlsx":
      return XlsSVG;
    case "ppt":
      return PptSVG;
    case "pptx":
      return PptSVG;
    case "zip":
      return ZipSVG;
    case "rar":
      return ZipSVG;
    case "7z":
      return ZipSVG;
    case "tar":
      return ZipSVG;
    case "gz":
      return ZipSVG;
    case "bz2":
      return ZipSVG;
    case "bz":
      return ZipSVG;
    case "tgz":
      return ZipSVG;
    case "tbz":
      return ZipSVG;
    case "tbz2":
      return ZipSVG;
    case "tb2":
      return ZipSVG;
    case "folder":
      return FolderSVG;
    // If the extension is not found, return the default icon
    default:
      return FileSVG;
  }
};
