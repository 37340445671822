import { AppContext } from "@components/features/appContext/AppContext";
import useFetchLatestCommand from "@hooks/discord/useFetchLatestCommand";
import { dateToReadDate } from "@utils/dateToReadDate";
import { translation } from "@utils/translation";
import { useContext } from "react";

const LatestCommand = () => {
  const { language } = useContext(AppContext);
  const { latestCommand } = useFetchLatestCommand(language);

  return (
    <div className="bg-tertiary rounded-md w-fit h-fit p-2">
      <div className="text-left mb-2 p-2">
        <h1 className="font-bold text-primary text-base">
          {translation(language).discord.commands.latestCommand}
        </h1>
        <h1 className="text-sm text-secondary">
          {translation(language).discord.commands.latestCommandDescription}
        </h1>
      </div>
      <div className="text-primary w-full h-full flex flex-col gap-2 items-center p-2">
        <div className="bg-primary px-5 py-3 w-full grid grid-cols-2 items-center">
          <p className="text-md text-primary">{latestCommand?.command?.name}</p>
          <p className="text-md text-primary">
            {latestCommand?.command?.description}
          </p>
        </div>
        <div className="bg-primary px-3 py-3 w-full grid grid-cols-3 items-center">
          <div className="flex flex-row items-center gap-2">
            <img
              alt="User"
              className="w-10 rounded-full bg-tertiary p-1"
              src={`https://cdn.discordapp.com/avatars/${latestCommand?.user?.id}/${latestCommand?.user?.avatar}.png`}
            />
            <p className="text-md text-primary">{latestCommand?.user?.name}</p>
          </div>
          <div className="text-primary font-bold text-xl text-center">-</div>
          <div className="flex flex-row items-center gap-2 ">
            <img
              alt="Server"
              className="w-10 rounded-full bg-tertiary p-1"
              src={`https://cdn.discordapp.com/icons/${latestCommand?.server?.id}/${latestCommand?.server?.icon}.png`}
            />
            <p className="text-md text-primary">
              {latestCommand?.server?.name}
            </p>
          </div>
        </div>
        <h1 className="bg-primary px-5 py-5 w-full text-center text-primary">
          {dateToReadDate(latestCommand?.date)}
        </h1>
      </div>
    </div>
  );
};

export default LatestCommand;
