import { executePostRequest } from "@api/client";
import { apiPath } from "@routes/app";
import { config } from "@utils/config";

export const registerUser = async (data: any, lang: string) => {
  const [promise] = executePostRequest(
    apiPath.register,
    data,
    lang,
    localStorage.getItem(config.localStorage.sessionToken) || ""
  );
  const result: any = await promise;
  return result.data;
};

export const loginUser = async (data: any, lang: string) => {
  const [promise] = executePostRequest(
    apiPath.login,
    data,
    lang,
    localStorage.getItem(config.localStorage.sessionToken) || ""
  );
  const result: any = await promise;
  return result.data;
};

export const logoutUser = async (data: any, lang: string) => {
  const [promise] = executePostRequest(
    apiPath.logout,
    data,
    lang,
    localStorage.getItem(config.localStorage.sessionToken) || ""
  );
  const result: any = await promise;
  return result.data;
};
