// URL for the API
export const baseURL = "https://api.martvanweeghel.nl"; //https://api.martvanweeghel.nl
// URL for the CDN (Content Delivery Network)
// - This is used for the images
export const cdnURL = "https://cdn.martvanweeghel.nl";

// Routes for the app
export const appPath = {
  login: "/login",
  dashboard: "/dashboard",
  cdn: "/cdn",
  portainer: {
    containers: "/portainer/containers",
  },
  projects: {
    main: "/projects",
    create: "/projects/create",
    edit: "/projects/edit/:id",
  },
  events: {
    main: "/events",
    create: "/events/create",
    edit: "/events/edit/:id",
  },
  skills: {
    main: "/skills",
    create: "/skills/create",
    edit: "/skills/edit/:id",
  },
  contacts: {
    main: "/contacts",
    create: "/contacts/create",
    edit: "/contacts/edit/:id",
  },
  users: {
    main: "/users",
    create: "/users/create",
    edit: "/users/edit/:id",
  },
  discord: {
    main: "/discord",
    commands: "/discord/commands",
  },
  settings: "/settings",
  logout: "/logout",
};

// Routes for the API
export const apiPath = {
  register: "/dashboard/register",
  login: "/dashboard/login",
  logout: "/dashboard/logout",
  session: "/dashboard/session",
  folder: "/dashboard/cdn/folder",
  upload: "/dashboard/cdn/upload",
  projects: "/martvanweeghel/projects",
  events: "/next/events",
  skills: "/martvanweeghel/skills",
  contacts: "/martvanweeghel/contacts",
  users: "/dashboard/users",
  commands: "/martvanweeghel/discord/commands",
  latestCommand: "/martvanweeghel/discord/latest",
  servers: "/martvanweeghel/discord/servers",
  containers: "/dashboard/portainer/list",
};

// Returns skill path for the API with the given id
export const createSkillPath = (id: string) => {
  return appPath.skills.edit.replace(":id", id).toString();
};

// Returns contact path for the API with the given id
export const createContactPath = (id: string) => {
  return appPath.contacts.edit.replace(":id", id).toString();
};

// Returns user path for the API with the given id
export const createUserPath = (id: string) => {
  return appPath.users.edit.replace(":id", id).toString();
};

// Returns project path for the API with the given id
export const createProjectPath = (id: string) => {
  return appPath.projects.edit.replace(":id", id).toString();
};

// Returns event path for the API with the given id
export const createEventPath = (id: string) => {
  return appPath.events.edit.replace(":id", id).toString();
};

// Returns project delete path for the API with the given id
export const createProjectWithIdPath = (id: string) => {
  return `/martvanweeghel/projects/${id}`;
};

// Returns file path for the API with the given id
export const createFilePath = (parent: string) => {
  return `/dashboard/cdn/files/${parent}`;
};

// Returns file delete path for the API with the given id
export const createFileDeletePath = (id: string) => {
  return `/dashboard/cdn/delete/file/${id}`;
};

// Returns folder path for the API with the given id
export const createFolderDeletePath = (id: string) => {
  return `/dashboard/cdn/delete/folder/${id}`;
};

// Returns skill delete path for the API with the given id
export const createSkillWithIdPath = (id: string) => {
  return `/martvanweeghel/skills/${id}`;
};

// Returns contact delete path for the API with the given id
export const createContactWithIdPath = (id: string) => {
  return `/martvanweeghel/contacts/${id}`;
};

// Returns contact delete path for the API with the given id
export const createEventWithIdPath = (id: string) => {
  return `/next/events/${id}`;
};

// Returns user delete path for the API with the given id
export const createUserWithIdPath = (id: string) => {
  return `/dashboard/users/${id}`;
};
