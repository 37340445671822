import { AppContext } from "@components/features/appContext/AppContext";
import BreadCumber from "@components/features/breadCumber/BreadCumber";
import InputField from "@components/primitives/form/InputField";
import Layout from "@components/primitives/layout/Layout";
import Toast from "@components/primitives/toast/Toast";
import usePostEvent from "@hooks/events/usePostEvent";
import { appPath } from "@routes/app";
import { createTitle } from "@utils/config";
import { translation } from "@utils/translation";
import { useContext, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";

const EventCreate = () => {
  const { language } = useContext(AppContext);
  const { mutate: postEvent, errorMessage, data } = usePostEvent(language);
  const navigate = useNavigate();

  useEffect(() => {
    if (!errorMessage) return;

    Toast("error", errorMessage);
  }, [errorMessage]);

  useEffect(() => {
    if (!data) return;

    Toast("success", data);
    navigate(appPath.events.main);
  }, [data, navigate]);

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    const title = e.target.title.value;
    const location = e.target.location.value;
    const startDate = e.target.startDate.value;
    const endDate = e.target.endDate.value;

    if (!title || !location || !startDate || !endDate) {
      return Toast("error", translation(language).general.error.emptyFields);
    }

    if (startDate > endDate) {
      return Toast("error", translation(language).general.error.starDateLaterThanEndDate);
    }

    const newEvent = {
      title,
      location,
      startDate,
      endDate,
    };

    await postEvent(newEvent);
  };

  return (
    <Layout permissionNeeded="events">
      <Helmet>
        <title>{createTitle(translation(language).sidebar.events.add)}</title>
      </Helmet>
      <h1 className="text-2xl text-primary">
        {translation(language).sidebar.events.add}
      </h1>
      <BreadCumber
        items={[
          {
            href: "",
            name: translation(language).sidebar.events.title,
            isLink: false,
          },
          {
            href: appPath.events.create,
            name: translation(language).sidebar.events.add,
            isLink: true,
          },
        ]}
      />

      <form className="relative py-6" onSubmit={(e: any) => handleSubmit(e)}>
        <div className="grid lg:grid-cols-2 gap-9">
          <InputField
            title={translation(language).events.all.table.title}
            name="title"
          />
          <InputField
            title={translation(language).events.all.table.location}
            name="location"
          />
          <InputField
            title={translation(language).events.all.table.startDate}
            type="datetime-local"
            name="startDate"
          />
          <InputField
            title={translation(language).events.all.table.endDate}
            type="datetime-local"
            name="endDate"
          />
        </div>
        <div className="border-t mt-6 pt-3">
          <button className="rounded text-gray-100 p-2 bg-blue-500 hover:shadow-inner hover:bg-blue-700 transition-all duration-300">
            {translation(language).general.actions.add}
          </button>
        </div>
      </form>
    </Layout>
  );
};

export default EventCreate;
