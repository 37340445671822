import { useMutation, useQueryClient } from "react-query";
import { deleteSkill } from "@repository/skillsRepository";

const useDeleteSkill = (lang: string) => {
  const queryClient = useQueryClient();
  const mutation = useMutation(
    (data: any) => {
      const { id } = data;
      return deleteSkill(id, lang);
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries("skills");
      },
    }
  );

  const { mutate, isLoading, isError, data, error }: any = mutation;
  return {
    mutate,
    isLoading,
    isError,
    data,
    errorMessage: error?.response.data,
  };
};

export default useDeleteSkill;
