import { AppContext } from "@components/features/appContext/AppContext";
import BreadCumber from "@components/features/breadCumber/BreadCumber";
import InputField from "@components/primitives/form/InputField";
import InputRange from "@components/primitives/form/InputRange";
import InputSelect from "@components/primitives/form/InputSelect";
import PreviewImage from "@components/primitives/form/PreviewImage";
import Layout from "@components/primitives/layout/Layout";
import Toast from "@components/primitives/toast/Toast";
import usePostSkill from "@hooks/skills/usePostSkill";
import { appPath } from "@routes/app";
import { createTitle } from "@utils/config";
import { translation } from "@utils/translation";
import { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";

const SkillsCreate = () => {
  const { language } = useContext(AppContext);
  const { mutate: postSkill, errorMessage, data } = usePostSkill(language);
  const navigate = useNavigate();

  const [previewPhoto, setPreviewPhoto] = useState("");

  useEffect(() => {
    if (!errorMessage) return;

    Toast("error", errorMessage);
  }, [errorMessage]);

  useEffect(() => {
    if (!data) return;

    Toast("success", data);
    navigate(appPath.skills.main);
  }, [data, navigate]);

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    const name = e.target.name.value;
    const icon = e.target.icon.value;
    const percentage = e.target.percentage.value;
    const isVisible = e.target.visible.value;

    if (!name || !icon || !percentage || isVisible === "select") {
      return Toast("error", translation(language).general.error.emptyFields);
    }

    const newSkill = {
      name,
      icon,
      percentage,
      isVisible: isVisible === "true",
    };

    await postSkill(newSkill);
  };

  return (
    <Layout permissionNeeded="skills">
      <Helmet>
        <title>{createTitle(translation(language).sidebar.skills.add)}</title>
      </Helmet>
      <h1 className="text-2xl text-primary">
        {translation(language).sidebar.skills.add}
      </h1>
      <BreadCumber
        items={[
          {
            href: "",
            name: translation(language).sidebar.skills.title,
            isLink: false,
          },
          {
            href: appPath.skills.create,
            name: translation(language).sidebar.skills.add,
            isLink: true,
          },
        ]}
      />

      <form className="relative py-6" onSubmit={(e: any) => handleSubmit(e)}>
        <div className="grid lg:grid-cols-2 gap-9">
          <InputField
            title={translation(language).skills.all.table.name}
            name="name"
          />
          <InputSelect
            title={translation(language).skills.all.table.isVisible.title}
            name="visible"
            options={[
              { label: translation(language).general.text.yes, value: true },
              { label: translation(language).general.text.no, value: false },
            ]}
          />
          <InputRange
            title={translation(language).skills.all.table.percentage}
            name="percentage"
            step={1}
            min={0}
            max={100}
          />
          <InputField
            title={translation(language).skills.all.table.icon}
            name="icon"
            onBlur={(e: any) => setPreviewPhoto(e.target.value)}
          />
          <div></div>
          <PreviewImage title="Preview" src={previewPhoto} />
        </div>
        <div className="border-t mt-6 pt-3">
          <button className="rounded text-gray-100 p-2 bg-blue-500 hover:shadow-inner hover:bg-blue-700 transition-all duration-300">
            {translation(language).general.actions.add}
          </button>
        </div>
      </form>
    </Layout>
  );
};

export default SkillsCreate;
