import { getContacts } from "@repository/contactsRepository";
import { useQuery } from "react-query";

const useFetchContacts = (lang: string) => {
  const { data, isLoading, isError } = useQuery(
    ["contacts"],
    () => getContacts(lang),
    {
      refetchInterval: 1000 * 60 * 60,
    }
  );
  return { contacts: data, isLoading, isError };
};

export default useFetchContacts;
