import { AppContext } from "@components/features/appContext/AppContext";
import Toast from "@components/primitives/toast/Toast";
import useDeleteFile from "@hooks/files/useDeleteFile";
import { cdnURL } from "@routes/app";
import { dateToReadDate } from "@utils/dateToReadDate";
import { FileType } from "@utils/FileType";
import { formatBytes } from "@utils/formatBytes";
import { translation } from "@utils/translation";
import { useContext } from "react";
//@ts-ignore
import FileViewer from "react-file-viewer";
import Swal, { SweetAlertResult } from "sweetalert2";
import InformationItem from "./IntormationItem";

type Props = {
  file: FileType;
  setFileInfo: (file: any) => void;
};

const FileInfo = ({ file, setFileInfo }: Props) => {
  const { language } = useContext(AppContext);
  const { mutate: deleteFile } = useDeleteFile(file.parent, language);

  const handleCopy = () => {
    navigator.clipboard.writeText(`${cdnURL}/${file._id}.${file.extension}`);
    Toast("success", translation(language).general.success.copied);
  };

  const handleDelete = () => {
    Swal.fire({
      title: translation(language).general.notification.prompt.title,
      text: translation(language).general.notification.prompt.text,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText:
        translation(language).general.notification.prompt.confirm,
      cancelButtonText:
        translation(language).general.notification.prompt.cancel,
    }).then((result: SweetAlertResult<any>) => {
      if (result.value) {
        Toast("success", translation(language).general.success.deleted);
        setFileInfo(null);
        deleteFile(file._id);
      } else {
        Toast("error", translation(language).general.not.deleted);
      }
    });
  };

  return (
    <div className="w-1/6 h-fit flex items-center justify-center">
      <div className="w-96 rounded-lg shadow-md flex flex-col p-5 bg-quinary">
        <div className="flex flex-col items-center">
          <FileViewer
            fileType={file.extension}
            filePath={`${cdnURL}/${file._id}.${file.extension}`}
          />
          <div className="h-0.5 bg-quaternary w-full my-3"> </div>
        </div>

        <div className="flex flex-col px-3 mt-2 gap-3">
          <InformationItem
            title={translation(language).cdn.table.file}
            label={file.filename}
          />
          <InformationItem
            title={translation(language).cdn.table.type}
            label={file.extension}
          />
          <InformationItem
            title={translation(language).cdn.table.size}
            label={formatBytes(file.size)}
          />
          <InformationItem
            title={translation(language).cdn.table.createdAt}
            label={dateToReadDate(file.createdAt)}
          />
          <InformationItem
            title={translation(language).cdn.table.changedAt}
            label={dateToReadDate(file.updatedAt)}
          />
        </div>

        <div className="h-0.5 bg-quaternary w-full my-3"> </div>

        <div className="flex flex-col px-3 my-2 gap-3">
          <div className="text-sm font-light text-primary flex flex-col">
            <p className="text-primary text-sm font-bold whitespace-nowrap">
              {translation(language).cdn.linkToCDN}
            </p>
            <a
              href={`${cdnURL}/${file._id}.${file.extension}`}
              target="_blank"
              rel="noreferrer"
              className="underline font-normal text-blue-500"
            >
              {translation(language).cdn.openLink}
            </a>
            <button
              onClick={() => handleCopy()}
              className="w-fit underline text-blue-500"
            >
              {translation(language).cdn.copyLink}
            </button>
          </div>
        </div>

        <div className="h-0.5 bg-quaternary w-full my-3"></div>

        <div className="px-3 my-2">
          <div className="text-sm font-light text-primary flex flex-col gap-2">
            <p className="text-primary text-sm font-bold whitespace-nowrap">
              {translation(language).general.text.actions}
            </p>
            <div className="flex flex-row gap-2">
              <button
                onClick={() => handleDelete()}
                className="bg-red-500 w-full h-full rounded text-white p-1"
              >
                {translation(language).general.actions.delete}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FileInfo;
