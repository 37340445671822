import { AppContext } from "@components/features/appContext/AppContext";
import BreadCumber from "@components/features/breadCumber/BreadCumber";
import Layout from "@components/primitives/layout/Layout";
import { appPath } from "@routes/app";
import { createTitle } from "@utils/config";
import { translation } from "@utils/translation";
import { useContext } from "react";
import { Helmet } from "react-helmet-async";
import FileManager from "./components/FileManager";

const FilePage = () => {
  const { language } = useContext(AppContext);

  return (
    <Layout permissionNeeded="cdn">
      <Helmet>
        <title>{createTitle(translation(language).sidebar.cdn.files)}</title>
      </Helmet>
      <h1 className="text-2xl text-primary">
        {translation(language).sidebar.cdn.files}
      </h1>
      <BreadCumber
        items={[
          {
            href: appPath.cdn,
            name: translation(language).sidebar.cdn.title,
            isLink: false,
          },
          {
            href: appPath.cdn,
            name: translation(language).sidebar.cdn.files,
            isLink: true,
          },
        ]}
      />
      <div className="mt-5 space-y-5">
        <FileManager />
      </div>
    </Layout>
  );
};

export default FilePage;
