export const dateToInputDate = (date: string) => {
  const dateTime = new Date(date);

  // Get date components
  const year = dateTime.getFullYear();
  const month = (dateTime.getMonth() + 1).toString().padStart(2, "0");
  const day = dateTime.getDate().toString().padStart(2, "0");

  // Get time components
  const hours = dateTime.getHours().toString().padStart(2, "0");
  const minutes = dateTime.getMinutes().toString().padStart(2, "0");

  // Format the string for YYYY-MM-DDTHH:mm
  const resultString = `${year}-${month}-${day}T${hours}:${minutes}`;

  return resultString;
};
