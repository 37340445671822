import { useMutation } from "react-query";
import { postSkill } from "@repository/skillsRepository";

const usePostSkill = (lang: string) => {
  // TODO: Fix automatic update
  const mutation = useMutation((data: void) => {
    return postSkill(data, lang);
  });

  const { mutate, isLoading, isError, data, error }: any = mutation;
  return {
    mutate,
    isLoading,
    isError,
    data,
    errorMessage: error?.response.data,
  };
};

export default usePostSkill;
