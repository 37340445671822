import { getFiles } from "@repository/filesRepository";
import { useQuery } from "react-query";

const useFetchFiles = (parent: any, lang: string) => {
  const { data, isLoading, isError } = useQuery(
    ["files", parent],
    () => getFiles(parent, lang),
    {
      refetchInterval: 1000 * 60 * 60,
    }
  );
  return { files: data, isLoading, isError };
};

export default useFetchFiles;
