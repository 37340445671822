import useFetchFiles from "@hooks/files/useFetchFiles";
import { FileType } from "@utils/FileType";
import { useContext, useEffect, useRef, useState } from "react";
import { ReactComponent as ArrowLeftSVG } from "@assets/images/arrow-left.svg";
import AddFile from "./AddFile";
import AddFolderPopup from "./AddFolderPopup";
import FileInfo from "./FileInfo";
import FileItem from "./FileItem";
import StorageNavigation from "./StorageNavigation";
import UploadFilePopup from "./UploadFilePopup";
import { translation } from "@utils/translation";
import { AppContext } from "@components/features/appContext/AppContext";
import classNames from "classnames";
import { useOutsideAlerter } from "@utils/useOutsideAlerter";

const FileManager = () => {
  const { language } = useContext(AppContext);
  const [parents, setParents] = useState([
    {
      _id: "",
      filename: "root",
      extension: "",
      path: "",
      createdAt: "",
      updatedAt: "",
    },
  ]) as any;
  const [fileInfo, setFileInfo] = useState(null);
  const { files } = useFetchFiles(parents[parents.length - 1]._id, language);

  const [isUploadFilePopupOpen, setIsUploadFilePopupOpen] = useState(false);
  const [isFolderPopupOpen, setIsFolderPopupOpen] = useState(false);

  const [isAddClicked, setIsAddClicked] = useState(false);

  const [isContextMenu, setIsContextMenu] = useState("");
  const dropDownRef = useRef(null);

  useOutsideAlerter(dropDownRef, () => {
    setIsAddClicked(false);
  });

  useEffect(() => {
    if (isAddClicked) {
      setIsAddClicked(false);
    }

    setFileInfo(null);
    setIsContextMenu("");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parents]);

  const handleBack = () => {
    if (parents.length > 1) {
      setParents(parents.slice(0, -1));
    }
  };

  const handleFileClick = () => {
    setIsUploadFilePopupOpen(true);
    setIsAddClicked(false);
  };

  const handleFolderClick = () => {
    setIsFolderPopupOpen(true);
    setIsAddClicked(false);
  };

  const arrowLeftClassName = classNames(
    "cursor-pointer hover:text-gray-400 text-primary my-1 ",
    {
      invisible: parents.length === 1,
    }
  );

  return (
    <div className="flex flex-row">
      <div className="w-5/6">
        <StorageNavigation items={parents} setParents={setParents} />
        <table className="w-full">
          <thead>
            <tr className="text-primary">
              <th className="text-left">
                {translation(language).cdn.table.file}
              </th>
              <th className="text-left">
                {translation(language).cdn.table.size}
              </th>
              <th className="text-left">
                {translation(language).cdn.table.createdAt}
              </th>
              <th className="text-left">
                {translation(language).cdn.table.changedAt}
              </th>
            </tr>
          </thead>
          <tbody className="space-y-2">
            <tr>
              <td>
                <ArrowLeftSVG
                  className={arrowLeftClassName}
                  onClick={() => handleBack()}
                />
              </td>
            </tr>
            {files?.length > 0 ? (
              files?.map((file: FileType, index: number) => {
                return (
                  <FileItem
                    key={index}
                    file={file}
                    parents={parents}
                    setParents={setParents}
                    setFileInfo={setFileInfo}
                    isContextMenu={isContextMenu}
                    setIsContextMenu={setIsContextMenu}
                  />
                );
              })
            ) : (
              <tr>
                <td>
                  <p className="text-secondary">
                    {translation(language).cdn.noFile}
                  </p>
                </td>
              </tr>
            )}
            <AddFile
              isAddClicked={isAddClicked}
              setIsAddClicked={setIsAddClicked}
            />
          </tbody>
        </table>
        {isAddClicked && (
          <div
            ref={dropDownRef}
            className="origin-top-right relative mt-2 text-primary w-1/2 rounded-md shadow-lg bg-quinary ring-1 ring-black ring-opacity-5 focus:outline-none"
          >
            <p
              onClick={() => handleFolderClick()}
              className="px-4 py-2 text-sm flex flex-row gap-2 hover:bg-quaternary rounded-t-md cursor-pointer"
            >
              {translation(language).cdn.folder}
            </p>
            <p
              onClick={() => handleFileClick()}
              className=" px-4 py-2 text-sm flex flex-row gap-2 hover:bg-quaternary rounded-b-md  cursor-pointer"
            >
              {translation(language).cdn.file}
            </p>
          </div>
        )}
      </div>
      {isUploadFilePopupOpen ? (
        <UploadFilePopup
          setIsUploadFilePopupOpen={setIsUploadFilePopupOpen}
          parentId={parents[parents.length - 1]._id}
        />
      ) : null}
      {isFolderPopupOpen ? (
        <AddFolderPopup
          setIsFolderPopupOpen={setIsFolderPopupOpen}
          parentId={parents[parents.length - 1]._id}
        />
      ) : null}
      {fileInfo && <FileInfo file={fileInfo} setFileInfo={setFileInfo} />}
    </div>
  );
};

export default FileManager;
