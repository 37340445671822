import ErrorPage from "@components/pages/errorPage/ErrorPage";
import { Routes, Route } from "react-router-dom";

type Props = {
  children: React.ReactNode | React.ReactNode[];
};

const RoutesWrapper = ({ children }: Props) => (
  <Routes>
    {children}
    <Route path="*" element={<ErrorPage error={404} />} />
  </Routes>
);

export default RoutesWrapper;
