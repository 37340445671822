export const en_US = {
  // General
  general: {
    text: {
      or: "or",
      name: "Name",
      actions: "Actions",
      backToHomePage: "Back to home page",
      yes: "Yes",
      no: "No",
      select: "Select",
    },
    actions: {
      add: "Add",
      upload: "Upload",
      cancel: "Cancel",
      save: "Save",
      close: "Close",
      delete: "Delete",
      edit: "Edit",
    },
    notification: {
      prompt: {
        title: "Are you sure?",
        text: "This action cannot be undone.",
        confirm: "Yes, I am sure!",
        cancel: "No, I want to do something else.",
      },
    },
    success: {
      created: "Successfully created",
      updated: "Successfully updated",
      uploaded: "Successfully uploaded",
      deleted: "Successfully deleted",
      copied: "Successfully copied",
    },
    not: {
      created: "Not created",
      updated: "Not updated",
      uploaded: "Not uploaded",
      deleted: "Not deleted",
    },
    error: {
      pageNotFound: "Page not found",
      somethingWentWrong: "Something went wrong. Try again.",
      starDateLaterThanEndDate: "Start date can't be later than the end date.",
      created: "Error occurred while creating",
      updated: "Error occurred while updating",
      deleted: "Error occurred while deleting",
      emptyFields: "All fields must be filled",
      passwordMustMatch: "Passwords must match",
      noAccess: "No access",
      noDataFound: "No data found",
      blocked: "Account has been blocked",
    },
  },

  // Sidebar
  sidebar: {
    general: {
      title: "General",
      dashboard: "Dashboard",
    },
    cdn: {
      title: "Content Delivery Network",
      files: "Files",
    },
    portainer: {
      title: "Portainer",
      containers: "Containers",
    },
    projects: {
      title: "Projects",
      all: "All projects",
      add: "Add project",
    },
    events: {
      title: "Events",
      all: "All events",
      add: "Add event",
    },
    skills: {
      title: "Skills",
      all: "All skills",
      add: "Add skill",
    },
    contacts: {
      title: "Contacts",
      all: "All contacts",
      add: "Add contact",
    },
    users: {
      title: "Users",
      all: "All users",
      add: "Add user",
    },
    discord: {
      title: "Discord",
      main: "Server information",
      commands: "All commands",
    },
    settings: {
      title: "Settings",
      general: "General",
      logout: "Logout",
    },
  },

  // Containers
  portainer: {
    containers: {
      title: "Containers",
      description: "All the containers that are running on Portainer",
      table: {
        name: "Name",
        image: "Image",
        status: "Status",
        created: "Created",
      },
    },
  },

  // Login
  login: {
    title: "Login",
    username: "Username",
    password: "Password",
    confirmPassword: "Repeat password",
    login: "Login",
    copyright: `© ${new Date().getFullYear()} Mart van Weeghel - All rights reserved.`,
    show: "Show",
    hide: "Hide",
    notification: {
      success: "Successfully logged in!",
      error: "Username or password is incorrect.",
    },
  },

  // Dashboard
  dashboard: {
    welcome: "Welcome",
    boxes: {
      cdn: {
        title: "Content Delivery Network",
        description:
          "Here you can upload files to the Content Delivery Network. These files will be available for every domain.",
      },
      projects: {
        title: "Projects",
        description:
          "Here you can create, edit, delete and upload projects. These are visible on www.martvanweeghel.nl",
        label: "Number of projects:",
      },
      events: {
        title: "Events",
        description:
          "Here you can create, edit, delete and see the events. These are visible on next.martvanweeghel.nl",
        label: "Number of events:",
      },
      skills: {
        title: "Skills",
        description:
          "Here you can create, edit, delete and upload skills. These are visible on www.martvanweeghel.nl",
        label: "Number of skills:",
      },
      users: {
        title: "Users",
        description:
          "Here you can create, edit, delete and see users. These users has access to dashboard.martvanweeghel.nl",
      },
      contacts: {
        title: "Contacts",
        description:
          "Here you can create, edit, delete and upload contacts. These are visible on www.martvanweeghel.nl and on music.martvanweeghel.nl",
        label: "Number of contacts:",
      },
      discord: {
        title: "Discord",
        description:
          "Here you can see the discord servers and commands that are used by the bot.",
        label: "Number of servers:",
      },
    },
  },

  // Project
  project: {
    all: {
      description:
        "Here you can see all projects. You can add, edit, delete and upload projects.",
      add: "Add project",
      table: {
        name: "Name",
        description: "Description",
        actions: "Actions",
        url: "URL",
        photo: "Photo",
        isVisible: {
          title: "Is visible?",
          visible: "Visible",
          hidden: "Hidden",
        },
        skills: "Project skills",
      },
    },
  },

  events: {
    all: {
      description:
        "Here you can see all the events that are visible on next.martvanweeghel.nl.",
      add: "Add event",
      table: {
        title: "Title",
        location: "Location",
        startDate: "Begindate",
        endDate: "Enddate",
        actions: "Actions",
      },
    },
  },

  // Users
  users: {
    all: {
      description:
        "Here you can add, edit and delete users. All the accounts below has access to the dashboard.",
      add: "Add user",
      table: {
        username: "Username",
        permissions: "Permissions",
        actions: "Actions",
        state: {
          title: "Is blocked?",
          blocked: "Blocked",
          notBlocked: "Not blocked",
        },
      },
    },
  },

  //CDN
  cdn: {
    table: {
      file: "File",
      type: "Type",
      size: "Size",
      createdAt: "Created at",
      changedAt: "Changed at",
    },
    noFile: "No file found",
    addFileOrFolder: "Add file or folder",
    file: "File",
    folder: "Folder",
    fileUpload: "File upload",
    folderCreate: "Folder create",
    dragFile: "Drag and drop a file here",
    searchFile: "Search file on your computer",
    linkToCDN: "Link to CDN",
    openLink: "Open link",
    copyLink: "Copy link",
  },

  // Skills
  skills: {
    all: {
      title: "Skills",
      description:
        "Here you can see all skills. You can add, edit, delete and upload skills.",
      addSkill: "Add skill",
      table: {
        skill: "Skill",
        name: "Name",
        icon: "Icon",
        percentage: "Percentage",
        isVisible: {
          title: "Is visible?",
          visible: "Visible",
          hidden: "Hidden",
        },
      },
    },
  },

  // Contacts
  contacts: {
    all: {
      title: "Contacts",
      description: {
        main: "Here you can see all contacts. You can add, edit, delete and upload contacts. These will show on www.martvanweeghel.nl",
        music:
          "Here you can see all contacts. You can add, edit, delete and upload contacts. These will show on music.martvanweeghel.nl",
      },
      addContact: "Add contact",
      table: {
        contact: "Contact",
        name: "Name",
        icon: "Icon",
        type: "Type",
        url: "URL",
        isVisible: {
          title: "Is visible?",
          visible: "Visible",
          hidden: "Hidden",
        },
      },
    },
  },

  // Discord
  discord: {
    main: {
      description: "Here you can see the information about the Discord server.",
      table: {
        guildId: "Guild ID",
        name: "Name",
        icon: "Icon",
        ownerId: "Owner ID",
        nameAcronym: "Name acronym",
        prefferedLocale: "Preffered locale",
      },
    },
    commands: {
      description:
        "Here you can see all commands that are available on the Discord server.",
      latestCommand: "Latest command",
      latestCommandDescription:
        "The latest command that was executed by the bot.",
      table: {
        name: "Name",
        description: "Description",
      },
    },
  },

  // Settings
  settings: {
    language: {
      title: "Language",
    },
  },
};
