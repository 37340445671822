import { getUsers } from "@repository/usersRepository";
import { useQuery } from "react-query";

const useFetchUsers = (lang: string) => {
  const { data, isLoading, isError } = useQuery(
    ["users"],
    () => getUsers(lang),
    {
      refetchInterval: 1000 * 60 * 60,
    }
  );
  return { users: data, isLoading, isError };
};

export default useFetchUsers;
