type Props = {
  title: string;
  name: string;
  value?: string;
};

const InputTextArea = ({ title, name, value }: Props) => (
  <div className="border focus-within:border-blue-500 focus-within:text-blue-500 bg-quaternary transition-all duration-500 relative rounded p-1 h-fit">
    <div className="-mt-6 absolute tracking-wider uppercase text-xs">
      <p>
        <label htmlFor={name} className="text-secondary px-1">
          {title}*
        </label>
      </p>
    </div>
    <p>
      <textarea
        id={name}
        name={name}
        defaultValue={value}
        autoComplete="false"
        tabIndex={0}
        className="py-1 px-1 text-primary outline-none block h-full w-full bg-transparent"
      />
    </p>
  </div>
);

export default InputTextArea;
