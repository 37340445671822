import { useMutation } from "react-query";
import { postProjects } from "@repository/projectRepository";

const usePostProject = (lang: string) => {
  // TODO: Fix automatic update
  const mutation = useMutation((data: void) => {
    return postProjects(data, lang);
  });

  const { mutate, isLoading, isError, data, error }: any = mutation;
  return {
    mutate,
    isLoading,
    isError,
    data,
    errorMessage: error?.response.data,
  };
};

export default usePostProject;
