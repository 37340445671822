import { appPath } from "@routes/app";
import React from "react";
import { Link } from "react-router-dom";
import Arrow from "./components/Arrow";

export type BreadItem = {
  name: string;
  isLink: boolean;
  href: string;
};

type Props = {
  items: BreadItem[] | [];
};

const BreadCumber = ({ items }: Props) => (
  <div className="flex items-center py-4 overflow-y-auto whitespace-nowrap">
    <Link to={appPath.dashboard} className="text-secondary">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="w-5 h-5"
        viewBox="0 0 20 20"
        fill="currentColor"
      >
        <path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z" />
      </svg>
    </Link>

    {items?.map((item: BreadItem, index: number) => (
      <React.Fragment key={index}>
        <Arrow />
        {item.isLink ? (
          <Link
            to={item.href}
            className="text-primary hover:underline font-bold"
          >
            {item.name}
          </Link>
        ) : (
          <span className="text-primary">{item.name}</span>
        )}
      </React.Fragment>
    ))}
  </div>
);

export default BreadCumber;
