import { ReactComponent as TrashSVG } from "@assets/images/trash.svg";
import { translation } from "@utils/translation";

type Props = {
  contextMenuRef: any;
  contextMenu: {
    x: number;
    y: number;
  };
  handleDelete: () => void;
  language: string;
};

const ContextMenu = ({
  contextMenuRef,
  contextMenu,
  handleDelete,
  language,
}: Props) => (
  <tr
    ref={contextMenuRef}
    className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-quinary ring-1 ring-black ring-opacity-5 focus:outline-none"
    style={{ top: contextMenu.y, left: contextMenu.x }}
  >
    <td
      onClick={() => handleDelete()}
      className="text-primary px-4 py-2 text-sm flex flex-row items-center gap-2 hover:bg-quaternary cursor-pointer"
    >
      <TrashSVG /> {translation(language).general.actions.delete}
    </td>
  </tr>
);

export default ContextMenu;
