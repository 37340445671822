type Props = {
  title: string;
  name: string;
  required?: boolean;
  options: { label: string; checked: boolean }[];
};

const InputCheckboxes = ({ title, name, required = true, options }: Props) => (
  <div className="border bg-quaternary transition-all duration-500 relative rounded p-1 w-full">
    <div className="-mt-6 absolute tracking-wider uppercase text-xs">
      <p>
        <label htmlFor={name} className="text-secondary px-1">
          {title}
          {required ? "*" : null}
        </label>
      </p>
    </div>
    <div className="py-2 px-2 text-primary flex flex-col gap-2">
      {options.map((o: any, index: number) => {
        return (
          <div key={index} className="grid grid-cols-2">
            <label>{o.label}</label>
            <input
              type="checkbox"
              name={o.label}
              id={o.label}
              defaultChecked={o.checked}
              autoComplete="false"
              tabIndex={0}
            />
          </div>
        );
      })}
    </div>
  </div>
);

export default InputCheckboxes;
