import Swal from "sweetalert2";

type Props = {
  type: "warning" | "error" | "success" | "info" | "question";
  title: string;
};

const Toast = (type: Props["type"], title: Props["title"]) => {
  return Swal.fire({
    toast: true,
    icon: type,
    title: title,
    position: "top-right",
    showConfirmButton: false,
    timer: 5000,
    timerProgressBar: true,
    didOpen: (toast: HTMLElement) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });
};

export default Toast;
