export const dateToReadDate = (date: string) => {
  // Split the date into parts
  const dateObject = new Date(date);
  const day =
    dateObject.getDate() < 10
      ? `0${dateObject.getDate()}`
      : dateObject.getDate();
  const month =
    dateObject.getMonth() < 9
      ? `0${dateObject.getMonth() + 1}`
      : dateObject.getMonth() + 1;
  const hours =
    dateObject.getHours() < 10
      ? `0${dateObject.getHours()}`
      : dateObject.getHours();
  const minutes =
    dateObject.getMinutes() < 10
      ? `0${dateObject.getMinutes()}`
      : dateObject.getMinutes();
  const seconds =
    dateObject.getSeconds() < 10
      ? `0${dateObject.getSeconds()}`
      : dateObject.getSeconds();

  // Return the date in a readable format
  // - 14/12/2019 12:00:00
  return `${day}-${month}-${dateObject.getFullYear()} ${hours}:${minutes}:${seconds}`;
};
