import { getServers } from "@repository/discordRepository";
import { useQuery } from "react-query";

const useFetchServers = (lang: string) => {
  const { data, isLoading, isError } = useQuery(
    ["servers"],
    () => getServers(lang),
    {
      refetchInterval: 1000 * 60 * 60,
    }
  );
  return { servers: data, isLoading, isError };
};

export default useFetchServers;
