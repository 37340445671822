import {
  executeDeleteRequest,
  executeGetRequest,
  executePostRequest,
  executePutRequest,
} from "@api/client";
import { apiPath, createContactWithIdPath } from "@routes/app";
import { config } from "@utils/config";

export const getContacts = async (lang: string) => {
  const [promise] = executeGetRequest(
    apiPath.contacts,
    localStorage.getItem(config.localStorage.sessionToken) || "",
    lang
  );
  const result: any = await promise;
  return result.data;
};

export const postContact = async (data: any, lang: string) => {
  const [promise] = executePostRequest(
    apiPath.contacts,
    data,
    lang,
    localStorage.getItem(config.localStorage.sessionToken) || ""
  );
  const result: any = await promise;
  return result.data;
};

export const putContact = async (id: string, data: any, lang: string) => {
  const [promise] = executePutRequest(
    createContactWithIdPath(id),
    data,
    localStorage.getItem(config.localStorage.sessionToken) || "",
    lang
  );
  const result: any = await promise;
  return result.data;
};

export const deleteContact = async (id: any, lang: string) => {
  const [promise] = executeDeleteRequest(
    createContactWithIdPath(id),
    localStorage.getItem(config.localStorage.sessionToken) || "",
    lang
  );
  const result: any = await promise;
  return result.data;
};
