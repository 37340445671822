import {
  executeDeleteRequest,
  executeGetRequest,
  executePostRequest,
} from "@api/client";
import {
  apiPath,
  createFileDeletePath,
  createFilePath,
  createFolderDeletePath,
} from "@routes/app";
import { config } from "@utils/config";

export const getFiles = async (parent: any, lang: string) => {
  const [promise] = executeGetRequest(
    createFilePath(parent),
    localStorage.getItem(config.localStorage.sessionToken) || "",
    lang
  );
  const result: any = await promise;
  return result.data;
};

export const postFolder = async (data: any, lang: string) => {
  const [promise] = executePostRequest(
    apiPath.folder,
    data,
    lang,
    localStorage.getItem(config.localStorage.sessionToken) || ""
  );
  const result: any = await promise;
  return result.data;
};

export const postFile = async (data: any, lang: string) => {
  const [promise] = executePostRequest(
    apiPath.upload,
    data,
    lang,
    localStorage.getItem(config.localStorage.sessionToken) || ""
  );
  const result: any = await promise;
  return result.data;
};

export const deleteFile = async (id: any, lang: string) => {
  const [promise] = executeDeleteRequest(
    createFileDeletePath(id),
    localStorage.getItem(config.localStorage.sessionToken) || "",
    lang
  );
  const result: any = await promise;
  return result.data;
};

export const deleteFolder = async (id: any, lang: string) => {
  const [promise] = executeDeleteRequest(
    createFolderDeletePath(id),
    localStorage.getItem(config.localStorage.sessionToken) || "",
    lang
  );
  const result: any = await promise;
  return result.data;
};
