const img = {
  display: "block",
  width: "100%",
  height: "100%",
};

const thumb = {
  display: "inline-flex",
  marginBottom: 8,
  marginRight: 8,
  width: 100,
  height: 100,
  padding: 4,
};

const thumbInner = {
  display: "flex",
  minWidth: 0,
  overflow: "hidden",
  width: "100%",
};

type Props = {
  file: any;
};

const Thumbs = ({ file }: Props) => (
  <div style={thumb} key={file?.name}>
    <div style={thumbInner}>
      <img src={file?.preview} style={img} alt={file?.name} />
    </div>
  </div>
);

export default Thumbs;
