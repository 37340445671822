type Props = {
  Icon: any;
  name: string;
  onClick: () => void;
};

const MenuButton = ({ Icon, name, onClick }: Props) => (
  <li>
    <button
      onClick={onClick}
      className="relative flex flex-row items-center w-full h-11 focus:outline-none hover:bg-quaternary text-primary border-l-4 border-transparent hover:border-blue-500 pr-6 ease-in-out duration-300"
    >
      <span className="inline-flex justify-center items-center ml-4">
        <Icon />
      </span>
      <span className="ml-2 text-sm tracking-wide truncate">{name}</span>
    </button>
  </li>
);

export default MenuButton;
