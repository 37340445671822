import { AppContext } from "@components/features/appContext/AppContext";
import { appPath } from "@routes/app";
import { createTitle } from "@utils/config";
import { translation } from "@utils/translation";
import { useContext } from "react";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";

type Props = {
  error: number;
  errorInfo?: string;
};

const ErrorPage = ({ error, errorInfo }: Props) => {
  const { language } = useContext(AppContext);
  if (error === 404) {
    errorInfo = translation(language).general.error.pageNotFound;
  }

  return (
    <div className="flex justify-center items-center w-full h-screen bg-primary">
      <div className="w-1/2  rounded-lg p-2 flex items-center justify-center flex-col bg-secondary">
        <Helmet>
          <title>{createTitle(`Error ${error}`)}</title>
        </Helmet>
        <h1 className="text-primary text-5xl m-5">{error}</h1>
        <h5 className="text-lg text-secondary">{errorInfo}</h5>
        <Link
          to={appPath.login}
          className="m-5 rounded bg-blue-500 hover:bg-blue-700 text-white p-2"
        >
          {translation(language).general.text.backToHomePage}
        </Link>
      </div>
    </div>
  );
};

export default ErrorPage;
