import { AppContext } from "@components/features/appContext/AppContext";
import { translation } from "@utils/translation";
import { useContext } from "react";
import Select from "react-select";

type Props = {
  title: string;
  name: string;
  required?: boolean;
  options: { label: string; value: any }[];
  value?: any;
  onChange?: (value: any) => void;
};

const InputSelect = ({
  title,
  required = true,
  name,
  options,
  value,
  onChange,
}: Props) => {
  const { language } = useContext(AppContext);
  const customStyles = {
    control: (base: any, _state: any) => ({
      ...base,
      padding: "1px",
      textColor: "rgb(17, 24, 39)",
      outline: "none",
      background: "transparent",
      color: "var(--color-text-primary)",
      border: "none",
    }),
    menu: (base: any) => ({
      ...base,
      color: "rgb(17, 24, 39)",
    }),
    singleValue: (base: any) => ({
      ...base,
      color: "var(--color-text-primary)",
    }),
    menuList: (base: any) => ({
      ...base,
    }),
  };

  const defaultValue = options.find(
    (option: { label: string; value: any }) => option.value === value
  ) || { label: translation(language).general.text.select, value: "select" };

  return (
    <div className="border focus-within:border-blue-500 focus-within:text-blue-500 bg-quaternary transition-all duration-500 relative rounded h-fit">
      <div className="-mt-6 absolute tracking-wider uppercase text-xs">
        <p>
          <label htmlFor={name} className="text-secondary px-1">
            {title}
            {required ? "*" : null}
          </label>
        </p>
      </div>
      <div>
        <Select
          defaultValue={defaultValue}
          styles={customStyles}
          id={name}
          name={name}
          tabIndex={0}
          options={options}
          onChange={onChange}
        />
      </div>
    </div>
  );
};

export default InputSelect;
