import { getLatestCommand } from "@repository/discordRepository";
import { LatestCommandType } from "@utils/CommandType";
import { useQuery } from "react-query";

const useFetchLatestCommand = (lang: string) => {
  const { data, isLoading, isError } = useQuery(
    ["latestCommand"],
    () => getLatestCommand(lang),
    {
      refetchInterval: 1000 * 60 * 60,
    }
  );
  return { latestCommand: data as LatestCommandType, isLoading, isError };
};

export default useFetchLatestCommand;
