import { AppContext } from "@components/features/appContext/AppContext";
import BreadCumber from "@components/features/breadCumber/BreadCumber";
import Layout from "@components/primitives/layout/Layout";
import useFetchServers from "@hooks/discord/useFetchServers";
import useFetchProjects from "@hooks/projects/useFetchProjects";
import useFetchSkills from "@hooks/skills/useFetchSkills";
import { appPath } from "@routes/app";
import { createTitle } from "@utils/config";
import { translation } from "@utils/translation";
import { useContext } from "react";
import { Helmet } from "react-helmet-async";
import BoxItem from "./components/BoxItem";
import useFetchContacts from "@hooks/contacts/useFetchContacts";
import useFetchEvents from "@hooks/events/useFetchEvents";

const DashboardPage = () => {
  const { user, language } = useContext(AppContext);
  const { projects } = useFetchProjects(language);
  const { skills } = useFetchSkills(language);
  const { servers } = useFetchServers(language);
  const { contacts } = useFetchContacts(language);
  const { events } = useFetchEvents(language);

  return (
    <Layout>
      <Helmet>
        <title>
          {createTitle(translation(language).sidebar.general.dashboard)}
        </title>
      </Helmet>
      <h1 className="text-2xl text-primary">
        {translation(language).sidebar.general.dashboard}
      </h1>
      <BreadCumber
        items={[
          {
            href: "",
            name: translation(language).sidebar.general.title,
            isLink: false,
          },
          {
            href: appPath.dashboard,
            name: translation(language).sidebar.general.dashboard,
            isLink: true,
          },
        ]}
      />
      <section>
        <div className="container px-6 py-10 mx-auto">
          <h1 className="text-3xl font-light text-primary lg:text-4xl">
            {translation(language).dashboard.welcome}, <br />{" "}
            <span className="font-bold">{user?.username}</span>
          </h1>
          <div className="grid grid-cols-1 gap-8 mt-8 xl:mt-12 xl:gap-12 md:grid-cols-2 xl:grid-cols-3">
            {user?.permissions?.cdn && (
              <BoxItem
                title={translation(language).dashboard.boxes.cdn.title}
                link={appPath.cdn}
                description={
                  translation(language).dashboard.boxes.cdn.description
                }
              />
            )}
            {user?.permissions?.projects && (
              <BoxItem
                title={translation(language).dashboard.boxes.projects.title}
                number={projects?.length}
                labelNumber={
                  translation(language).dashboard.boxes.projects.label
                }
                description={
                  translation(language).dashboard.boxes.projects.description
                }
                link={appPath.projects.main}
              />
            )}
            {user?.permissions?.skills && (
              <BoxItem
                title={translation(language).dashboard.boxes.skills.title}
                number={skills?.length}
                labelNumber={translation(language).dashboard.boxes.skills.label}
                description={
                  translation(language).dashboard.boxes.skills.description
                }
                link={appPath.skills.main}
              />
            )}
            {user?.permissions?.users && (
              <BoxItem
                title={translation(language).dashboard.boxes.users.title}
                link={appPath.users.main}
                description={
                  translation(language).dashboard.boxes.users.description
                }
              />
            )}
            {user?.permissions?.contacts && (
              <BoxItem
                title={translation(language).dashboard.boxes.contacts.title}
                number={contacts?.length}
                labelNumber={
                  translation(language).dashboard.boxes.contacts.label
                }
                description={
                  translation(language).dashboard.boxes.contacts.description
                }
                link={appPath.contacts.main}
              />
            )}
            {user?.permissions?.events && (
              <BoxItem
                title={translation(language).dashboard.boxes.events.title}
                number={events?.length}
                labelNumber={translation(language).dashboard.boxes.events.label}
                description={
                  translation(language).dashboard.boxes.events.description
                }
                link={appPath.events.main}
              />
            )}
            {user?.permissions?.discord && (
              <BoxItem
                title={translation(language).dashboard.boxes.discord.title}
                number={servers?.length}
                labelNumber={
                  translation(language).dashboard.boxes.discord.label
                }
                description={
                  translation(language).dashboard.boxes.discord.description
                }
                link={appPath.discord.main}
              />
            )}
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default DashboardPage;
