import { getCommands } from "@repository/discordRepository";
import { useQuery } from "react-query";

const useFetchCommands = (lang: string) => {
  const { data, isLoading, isError } = useQuery(
    ["commands"],
    () => getCommands(lang),
    {
      refetchInterval: 1000 * 60 * 60,
    }
  );
  return { commands: data, isLoading, isError };
};

export default useFetchCommands;
