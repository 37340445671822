import DashboardPage from "@components/pages/dashboardPage/DashboardPage";
import RoutesWrapper from "@components/primitives/routesWrapper/RoutesWrapper";
import { QueryClient, QueryClientProvider } from "react-query";
import { BrowserRouter, Route } from "react-router-dom";
import FilePage from "@components/pages/cdnPages/filePage/FilePage";
import AppContextProvider from "@components/features/appContext/AppContext";
import { appPath } from "@routes/app";
import Projects from "@components/pages/projectPages/projects/Projects";
import ProjectCreate from "@components/pages/projectPages/projectCreate/ProjectCreate";
import Skills from "@components/pages/skillsPage/skills/Skills";
import SkillsCreate from "@components/pages/skillsPage/skillsCreate/SkillsCreate";
import SkillEdit from "@components/pages/skillsPage/skillEdit/SkillEdit";
import ProjectEdit from "@components/pages/projectPages/projectEdit/ProjectEdit";
import LoginPage from "@components/pages/loginPage/LoginPage";
import SettingsPage from "@components/pages/settingsPage/SettingsPage";
import DiscordPage from "@components/pages/discordPage/DiscordPage";
import CommandsPage from "@components/pages/discordPage/CommandsPage";
import { HelmetProvider } from "react-helmet-async";
import Containers from "@components/pages/containersPage/Containers";
import Users from "@components/pages/usersPage/users/Users";
import UserCreate from "@components/pages/usersPage/userCreate/UserCreate";
import UserEdit from "@components/pages/usersPage/userEdit/UserEdit";
import Contacts from "@components/pages/contactsPage/contacts/Contacts";
import ContactsCreate from "@components/pages/contactsPage/contactsCreate/ContactsCreate";
import ContactEdit from "@components/pages/contactsPage/contactEdit/ContactEdit";
import Events from "@components/pages/eventsPages/events/Events";
import EventCreate from "@components/pages/eventsPages/eventCreate/EventCreate";
import EventEdit from "@components/pages/eventsPages/eventEdit/EventEdit";

const queryClient = new QueryClient();

const App = () => (
  <QueryClientProvider client={queryClient}>
    <HelmetProvider>
      <AppContextProvider>
        <BrowserRouter>
          <RoutesWrapper>
            <Route path="/" element={<LoginPage />} />
            <Route path={appPath.login} element={<LoginPage />} />
            <Route path={appPath.dashboard} element={<DashboardPage />} />
            <Route path={appPath.cdn} element={<FilePage />} />
            <Route path={appPath.projects.main} element={<Projects />} />
            <Route path={appPath.projects.create} element={<ProjectCreate />} />
            <Route path={appPath.projects.edit} element={<ProjectEdit />} />
            <Route path={appPath.events.main} element={<Events />} />
            <Route path={appPath.events.create} element={<EventCreate />} />
            <Route path={appPath.events.edit} element={<EventEdit />} />
            <Route path={appPath.skills.main} element={<Skills />} />
            <Route path={appPath.skills.create} element={<SkillsCreate />} />
            <Route path={appPath.skills.edit} element={<SkillEdit />} />
            <Route path={appPath.contacts.main} element={<Contacts />} />
            <Route
              path={appPath.contacts.create}
              element={<ContactsCreate />}
            />
            <Route path={appPath.contacts.edit} element={<ContactEdit />} />
            <Route path={appPath.users.main} element={<Users />} />
            <Route path={appPath.users.create} element={<UserCreate />} />
            <Route path={appPath.users.edit} element={<UserEdit />} />
            <Route path={appPath.discord.main} element={<DiscordPage />} />
            <Route path={appPath.discord.commands} element={<CommandsPage />} />
            <Route
              path={appPath.portainer.containers}
              element={<Containers />}
            />
            <Route path={appPath.settings} element={<SettingsPage />} />
            <Route path={appPath.logout} element={<LoginPage />} />
          </RoutesWrapper>
        </BrowserRouter>
      </AppContextProvider>
    </HelmetProvider>
  </QueryClientProvider>
);

export default App;
