import { useMutation } from "react-query";
import { postContact } from "@repository/contactsRepository";

const usePostContact = (lang: string) => {
  // TODO: Fix automatic update
  const mutation = useMutation((data: void) => {
    return postContact(data, lang);
  });

  const { mutate, isLoading, isError, data, error }: any = mutation;
  return {
    mutate,
    isLoading,
    isError,
    data,
    errorMessage: error?.response.data,
  };
};

export default usePostContact;
