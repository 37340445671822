import { AppContext } from "@components/features/appContext/AppContext";
import BreadCumber from "@components/features/breadCumber/BreadCumber";
import InputField from "@components/primitives/form/InputField";
import InputSelect from "@components/primitives/form/InputSelect";
import PreviewImage from "@components/primitives/form/PreviewImage";
import Layout from "@components/primitives/layout/Layout";
import Toast from "@components/primitives/toast/Toast";
import usePostContact from "@hooks/contacts/usePostContact";
import { appPath } from "@routes/app";
import { createTitle } from "@utils/config";
import { translation } from "@utils/translation";
import { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";

const ContactsCreate = () => {
  const { language } = useContext(AppContext);
  const { mutate: postContact, errorMessage, data } = usePostContact(language);
  const navigate = useNavigate();

  const [previewPhoto, setPreviewPhoto] = useState("");

  useEffect(() => {
    if (!errorMessage) return;

    Toast("error", errorMessage);
  }, [errorMessage]);

  useEffect(() => {
    if (!data) return;

    Toast("success", data);
    navigate(appPath.contacts.main);
  }, [data, navigate]);

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    const name = e.target.name.value;
    const icon = e.target.icon.value;
    const url = e.target.url.value;
    const type = e.target.type.value;
    const isVisible = e.target.visible.value;

    if (!name || !icon || !url || type === "select" || isVisible === "select") {
      return Toast("error", translation(language).general.error.emptyFields);
    }

    const newContact = {
      name,
      icon,
      url,
      type,
      isVisible: isVisible === "true",
    };

    await postContact(newContact);
  };

  return (
    <Layout permissionNeeded="contacts">
      <Helmet>
        <title>{createTitle(translation(language).sidebar.contacts.add)}</title>
      </Helmet>
      <h1 className="text-2xl text-primary">
        {translation(language).sidebar.contacts.add}
      </h1>
      <BreadCumber
        items={[
          {
            href: "",
            name: translation(language).sidebar.contacts.title,
            isLink: false,
          },
          {
            href: appPath.contacts.create,
            name: translation(language).sidebar.contacts.add,
            isLink: true,
          },
        ]}
      />

      <form className="relative py-6" onSubmit={(e: any) => handleSubmit(e)}>
        <div className="grid lg:grid-cols-2 gap-9">
          <InputField
            title={translation(language).contacts.all.table.name}
            name="name"
          />
          <InputSelect
            title={translation(language).contacts.all.table.isVisible.title}
            name="visible"
            options={[
              { label: translation(language).general.text.yes, value: true },
              { label: translation(language).general.text.no, value: false },
            ]}
          />
          <InputSelect
            title={translation(language).contacts.all.table.type}
            name="type"
            options={[
              { label: "main", value: "main" },
              { label: "music", value: "music" },
            ]}
          />
          <InputField
            title={translation(language).contacts.all.table.url}
            name="url"
          />
          <InputField
            title={translation(language).contacts.all.table.icon}
            name="icon"
            onBlur={(e: any) => setPreviewPhoto(e.target.value)}
          />
          <PreviewImage title="Preview" src={previewPhoto} isRounded={true} />
        </div>
        <div className="border-t mt-6 pt-3">
          <button className="rounded text-gray-100 p-2 bg-blue-500 hover:shadow-inner hover:bg-blue-700 transition-all duration-300">
            {translation(language).general.actions.add}
          </button>
        </div>
      </form>
    </Layout>
  );
};

export default ContactsCreate;
