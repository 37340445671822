import { AppContext } from "@components/features/appContext/AppContext";
import BreadCumber from "@components/features/breadCumber/BreadCumber";
import AlertBox from "@components/primitives/alertBox/AlertBox";
import Layout from "@components/primitives/layout/Layout";
import useFetchServers from "@hooks/discord/useFetchServers";
import { appPath } from "@routes/app";
import { createTitle } from "@utils/config";
import { ServerType } from "@utils/ServerType";
import { translation } from "@utils/translation";
import { useContext } from "react";
import { Helmet } from "react-helmet-async";
import Server from "./components/Server";

const DiscordPage = () => {
  const { language } = useContext(AppContext);
  const { servers } = useFetchServers(language);

  return (
    <Layout permissionNeeded="discord">
      <Helmet>
        <title>{createTitle(translation(language).sidebar.discord.main)}</title>
      </Helmet>
      <h1 className="text-2xl text-primary">
        {translation(language).sidebar.discord.main}
      </h1>
      <BreadCumber
        items={[
          {
            href: "",
            name: translation(language).sidebar.discord.title,
            isLink: false,
          },
          {
            href: appPath.discord.main,
            name: translation(language).sidebar.discord.main,
            isLink: true,
          },
        ]}
      />

      <div className="col-span-12 mt-5">
        <div className="grid gap-2 grid-cols-1 lg:grid-cols-1">
          <div className="bg-tertiary p-4 shadow-lg rounded-lg">
            <div className="flex flex-row justify-between items-center">
              <div>
                <h1 className="font-bold text-base text-primary">
                  {translation(language).sidebar.discord.main}
                </h1>
                <p className="text-sm text-secondary">
                  {translation(language).discord.main.description}
                </p>
              </div>
            </div>
            <div className="mt-4">
              <div className="flex flex-col">
                <div className="-my-2 overflow-x-auto">
                  <div className="py-2 align-middle inline-block min-w-full">
                    <table className="min-w-full divide-y divide-gray-200">
                      <thead>
                        <tr>
                          <th className="px-6 py-3 bg-secondary text-primary text-xs leading-4 font-medium  uppercase tracking-wider">
                            <div className="flex cursor-pointer">
                              <span className="mr-2 uppercase">
                                {
                                  translation(language).discord.main.table
                                    .guildId
                                }
                              </span>
                            </div>
                          </th>
                          <th className="px-6 py-3 bg-secondary text-primary text-xs leading-4 font-medium  uppercase tracking-wider">
                            <div className="flex cursor-pointer">
                              <span className="mr-2 uppercase">
                                {translation(language).discord.main.table.name}
                              </span>
                            </div>
                          </th>

                          <th className="px-6 py-3 bg-secondary text-primary text-xs leading-4 font-medium  uppercase tracking-wider">
                            <div className="flex cursor-pointer">
                              <span className="mr-2 uppercase">
                                {
                                  translation(language).discord.main.table
                                    .ownerId
                                }
                              </span>
                            </div>
                          </th>
                          <th className="px-6 py-3 bg-secondary text-primary text-xs leading-4 font-medium  uppercase tracking-wider">
                            <div className="flex cursor-pointer">
                              <span className="mr-2 uppercase">
                                {
                                  translation(language).discord.main.table
                                    .prefferedLocale
                                }
                              </span>
                            </div>
                          </th>
                        </tr>
                      </thead>
                      <tbody className="bg-primary text-primary divide-y divide-gray-200">
                        {servers ? (
                          servers?.length > 0 ? (
                            servers?.map((server: ServerType) => (
                              <Server key={server._id} server={server} />
                            ))
                          ) : (
                            <tr>
                              <td colSpan={4} className="pt-5">
                                <AlertBox
                                  type="info"
                                  message={
                                    translation(language).general.error
                                      .noDataFound
                                  }
                                />
                              </td>
                            </tr>
                          )
                        ) : (
                          <tr>
                            <td colSpan={4} className="pt-5">
                              <AlertBox
                                type="danger"
                                message={
                                  translation(language).general.error
                                    .somethingWentWrong
                                }
                              />
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default DiscordPage;
