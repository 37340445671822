import { AppContext } from "@components/features/appContext/AppContext";
import { translation } from "@utils/translation";
import { useContext } from "react";

type Props = {
  isAddClicked: boolean;
  setIsAddClicked: (isClicked: boolean) => void;
};
const AddFile = ({ isAddClicked, setIsAddClicked }: Props) => {
  const { language } = useContext(AppContext);

  const handleClick = () => {
    setIsAddClicked(!isAddClicked);
  };

  return (
    <tr
      onClick={() => handleClick()}
      className={`hover:bg-quaternary text-primary cursor-pointer h-full ${
        isAddClicked ? "bg-quaternary" : null
      }`}
    >
      <td className="text-left">
        <div className="flex flex-row items-center gap-5 ">
          <p>+</p>
          <p>{translation(language).cdn.addFileOrFolder}</p>
        </div>
      </td>
      <td className="text-left text-lg">
        <div className="flex flex-row items-center gap-1">
          <p>-</p>
        </div>
      </td>
      <td className="text-left text-lg">
        <div className="flex flex-row items-center gap-1">
          <p>-</p>
        </div>
      </td>
      <td className="text-left text-lg">
        <div className="flex flex-row items-center gap-1">
          <p>-</p>
        </div>
      </td>
    </tr>
  );
};

export default AddFile;
